import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';

interface ModalAction {
  title: string;
  className?: string;
  disabled?: boolean;
  cb: (e: React.MouseEvent) => void;
}
interface ModalProps {
  title: string;
  open?: boolean;
  onClose: () => void;
  actions: ModalAction[];
  hasBlurBdf?: boolean;
  containerClassName?: string;
  children: React.ReactNode;
}
const ModalComponent: React.FC<ModalProps> = ({
  title,
  actions,
  open = true,
  hasBlurBdf = false,
  containerClassName = 'max-w-md overflow-hidden',
  children,
  onClose,
}) => {
  return (
    <Transition appear={true} show={open} as={Fragment}>
      <Dialog
        open={open}
        className={clsx(
          'fixed inset-0 z-10 overflow-y-auto',
          hasBlurBdf && 'backdrop-blur-sm'
        )}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={clsx(
                'inline-block w-full my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-md',
                containerClassName
              )}
            >
              <Dialog.Title className="font-medium text-base bg-gray-50 border-gray-200 border-b px-4 py-2">
                {title}
              </Dialog.Title>
              {children}

              <div className="m-4 mt-0 space-x-2 text-right">
                {actions.map(({ title, className, disabled, cb }, i) => (
                  <button
                    key={title}
                    type="button"
                    className={clsx(
                      'px-2 py-1 text-sm cursor-default bg-gray-200 hover:bg-gray-300 rounded-sm focus-lg transition-colors duration-75',
                      className,
                      disabled && 'opacity-50 cursor-not-allowed'
                    )}
                    onClick={cb}
                    disabled={disabled}
                  >
                    {title}
                  </button>
                ))}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const Modal = React.memo(ModalComponent);
export default ModalComponent;
