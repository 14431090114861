import { gql } from '@apollo/client';

export const StockMediasFragments = {
  StockMediaResults: gql`
    fragment StockMediaResults on StockMediaResults {
      total
      totalPages
      page
      perPage
      rateLimit
      rateLimitRemaining
      results {
        id
        color
        description
        altDescription
        src {
          original {
            url
            height
            width
          }
          large {
            url
            height
            width
          }
          medium {
            url
            height
            width
          }
          small {
            url
            height
            width
          }
        }
        previewURL
        pageURL
        userName
        userURL
        sourceName
        sourceURL
      }
    }
  `,
};

export const SearchStockMediaQuery = gql`
  query SearchStockMediaQuery(
    $query: String!
    $source: StockMediaSource!
    $orientation: StockMediaOrientation
    $page: Int
    $perPage: Int
  ) {
    searchStockMedia(
      data: {
        query: $query
        source: $source
        orientation: $orientation
        page: $page
        perPage: $perPage
      }
    ) {
      ...StockMediaResults
    }
  }
  ${StockMediasFragments.StockMediaResults}
`;
