import React, { useCallback } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { useAuth } from '../auth/auth';
import { menuRight, menuMain, routePath } from '../_data/menus';
import clsx from 'clsx';

import { CustomLink } from '../custom-link/custom-link';

export const NavMobile: React.FC = () => {
  const { user, isAuthenticated } = useAuth();
  const { pathname } = useRouter();

  const handleOnShow = useCallback((open: boolean) => {
    if (open) {
      document?.body?.classList?.add('w-full', 'fixed');
    } else {
      document?.body?.classList?.remove('w-full', 'fixed');
    }
  }, []);

  return (
    <div className="relative flex md:hidden">
      <Menu>
        {({ open }) => {
          return (
            <>
              <Menu.Button
                className={clsx(
                  '-mr-2 inline-flex justify-center items-center rounded-md p-2 text-sm font-medium leading-5',
                  'hover:text-white focus-lg focus:ring-white',
                  open ? 'text-white' : 'text-gray-400'
                )}
                title={open ? 'Close navigation menu' : 'Open navigation menu'}
              >
                {!open ? (
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </Menu.Button>

              <Transition
                show={open}
                enter=" ease-in-out duration-100 transform"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave=" ease-in-out duration-100 transform"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="fixed inset-0 top-14 h-screen z-90"
                beforeEnter={() => handleOnShow(true)}
                beforeLeave={() => handleOnShow(false)}
              >
                <Menu.Items
                  static
                  className="h-full bg-gray-800 origin-top focus:outline-none"
                >
                  {!isAuthenticated && (
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                      {menuMain
                        .filter((item) => item.protected === !!user)
                        .map((item) => (
                          <Menu.Item key={item.id}>
                            {() => (
                              <CustomLink
                                href={item.link}
                                className={clsx(
                                  'block px-3 py-2 rounded-md text-base font-medium focus:outline-none',
                                  pathname.startsWith(item.link)
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                                )}
                              >
                                {item.name}
                              </CustomLink>
                            )}
                          </Menu.Item>
                        ))}
                    </div>
                  )}

                  <div className="pt-4 pb-3 border-t border-gray-700">
                    {!!user && (
                      <div className="flex items-center mb-3 px-5">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-10 w-10 rounded-full"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                            <path
                              fillRule="evenodd"
                              d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                            />
                          </svg>
                        </div>
                        <div className="ml-3">
                          <div className="text-base font-medium leading-none text-white">
                            {user.firstName} {user.lastName}
                          </div>
                          <div className="mt-1 text-sm font-medium leading-none text-gray-400">
                            {user.email}
                          </div>
                        </div>
                        <button className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                          <span className="sr-only">View notifications</span>
                          {/** <!-- Heroicon name: bell --> */}
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                    <div className="px-2 space-y-1">
                      {menuRight
                        .filter((item) => item.protected === !!user)
                        .map((item) => (
                          <Menu.Item key={item.id}>
                            {() => (
                              <CustomLink
                                href={item.link}
                                className={clsx(
                                  'block px-3 py-2 rounded-md text-base font-medium focus:outline-none',
                                  pathname.startsWith(item.link)
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                  item.id === 'signup' &&
                                    'bg-indigo-600 hover:bg-indigo-700'
                                )}
                              >
                                {item.name}
                              </CustomLink>
                            )}
                          </Menu.Item>
                        ))}
                      {!!user && (
                        <Menu.Item>
                          {({ active }) => (
                            <CustomLink
                              href={routePath.logout}
                              className={clsx(
                                'block px-3 py-2 rounded-md text-base font-medium focus:outline-none',
                                active
                                  ? 'text-white bg-gray-700'
                                  : 'text-gray-300'
                              )}
                            >
                              Sign out
                            </CustomLink>
                          )}
                        </Menu.Item>
                      )}
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    </div>
  );
};

export default NavMobile;
