import React from 'react';

export interface Empty {
  type?: 'error' | 'infos';
  title: string;
  icon?: React.ReactNode;
  messages: string[];
  actionLabel?: string;
  onActionClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const EmptyComponent: React.FC<Empty> = ({
  type = 'infos',
  icon = '',
  title,
  messages,
  actionLabel,
  onActionClick,
}) => {
  return (
    <div className="flex flex-col w-full h-full justify-center items-center text-center rounded-md p-5">
      {type === 'error' ? (
        <svg
          className="h-40 w-40 text-gray-300 mb-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM4.146 5.146a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 1 1 .708.708l-.647.646.647.646a.5.5 0 1 1-.708.708L5.5 7.207l-.646.647a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708zm5 0a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708zM8 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
        </svg>
      ) : (
        icon
      )}

      <h3 className="text-base font-medium">{title}</h3>
      {messages.map((message, i) => (
        <p key={i} className="text-sm whitespace-pre-line">
          {message}
        </p>
      ))}
      {!!actionLabel && (
        <button
          type="button"
          className="inline-flex items-center mt-4 px-4 py-2 border border-gray-300 rounded-md text-sm font-medium bg-transparent transition-colors hover:bg-gray-300 select-none focus-md"
          onClick={onActionClick}
        >
          {actionLabel}
        </button>
      )}
    </div>
  );
};

export const Empty = React.memo(EmptyComponent);
export default EmptyComponent;
