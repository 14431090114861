import React, { Dispatch, SetStateAction, useState } from 'react';
import { createContainer } from 'react-tracked';

export interface BreadcrumbStateInterface {
  show: boolean;
  data: Record<number, { label: string; href: string }> | null;
}

const INITIAL_STATE: BreadcrumbStateInterface = { show: false, data: null };

const useBreadcrumbValue = () =>
  useState<BreadcrumbStateInterface>(INITIAL_STATE);
export const {
  Provider: BreadCrumbProvider,
  useTrackedState: useBreadcrumbState,
  useUpdate: useSetBreadcrumbState,
  useSelector: useBreadcrumbSelector,
} = createContainer<
  BreadcrumbStateInterface,
  Dispatch<SetStateAction<BreadcrumbStateInterface>>,
  { children?: React.ReactNode }
>(useBreadcrumbValue);
