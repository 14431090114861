import clsx from 'clsx';
import React from 'react';
import { AlertType, AlertOptions } from 'react-alert';

export interface AlertProps {
  style: React.CSSProperties;
  options: AlertOptions;
  message: React.ReactNode;
  close: () => void;
}

const getStyleByType = (type: AlertType | 'warning') => {
  switch (type) {
    case 'info':
      return 'border-blue-500 text-blue-500';
    case 'success':
      return 'border-green-500 text-green-500';
    case 'error':
      return 'animate-shake border-red-500 text-red-500';
    case 'warning':
      return 'border-yellow-500 text-yellow-500';
    default:
      return 'border-gray-500 text-gray-500';
  }
};

const getIconByType = (type: AlertType | 'warning') => {
  if (type === 'info')
    return (
      <svg
        className="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
      </svg>
    );
  if (type === 'success')
    return (
      <svg
        className="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
      </svg>
    );
  if (type === 'error')
    return (
      <svg
        className="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
      </svg>
    );
  if (type === 'warning')
    return (
      <svg
        className="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
      </svg>
    );
  return null;
};

const AlertComponent: React.FC<AlertProps> = ({
  style,
  options,
  message,
  close,
}) => {
  return (
    <div
      style={style}
      className={clsx(
        'md:w-80 max-h-40 overflow-auto rounded-[0.25rem] bg-gray-700 border-l-4 shadow',
        getStyleByType(options.type as AlertType)
      )}
    >
      <div className="flex m-2 space-x-3 items-center">
        <div className="">{getIconByType(options.type as AlertType)}</div>

        <div className="inline-flex flex-col flex-1 text-gray-200 overflow-hidden">
          {message}
        </div>
        <div className="inline-flex">
          <button
            type="button"
            aria-label="Close alert"
            onClick={close}
            className="cursor-default rounded-sm text-gray-200 hover:opacity-50 focus:outline-none focus:ring-1 focus:ring-white"
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export const Alert = React.memo(AlertComponent);
export default AlertComponent;
