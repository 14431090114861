import { gql } from '@apollo/client';

export const UsersFragments = {
  UserBasic: gql`
    fragment UserBasic on User {
      id
      firstName
      lastName
      email
    }
  `,
};

export const MeQuery = gql`
  query MeQuery {
    me {
      ...UserBasic
    }
  }
  ${UsersFragments.UserBasic}
`;
