import React from 'react';
import Spinner from '../spinner/spinner';

interface LoadingScreenProps {
  title?: string;
}
export const LoadingScreen: React.FC<LoadingScreenProps> = ({ title }) => {
  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center bg-gray-200">
      <Spinner className="h-14 w-14 text-gray-400" />
      {title && <p className="mt-2 font-medium text-gray-400">{title}</p>}
    </div>
  );
};

export default LoadingScreen;
