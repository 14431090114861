import { gql } from '@apollo/client';
import {
  AssetSource,
  OrderDirection,
  QueryUserProjectAssetsArgs,
  QueryUserProjectMediasArgs,
  QueryUserProjectsArgs,
} from './generated/graphql';
import { MediasFragments } from './medias';

const projectFullFragment = gql`
  fragment ProjectFull on Project {
    createdAt
    favorite
    id
    name
    totalMedias
    description
    updatedAt
    templateId
    template {
      id
      updatedAt
      createdAt
    }
    creator {
      id
      firstName
      lastName
    }
  }
`;

export const ProjectsFragments = {
  ProjectBasic: gql`
    fragment ProjectBasic on Project {
      createdAt
      favorite
      id
      name
      totalMedias
      updatedAt
      templateId
      template {
        id
        updatedAt
        createdAt
      }
    }
  `,
  ProjectAsset: gql`
    fragment ProjectAsset on ProjectAsset {
      createdAt
      updatedAt
      createdBy
      id
      uiId
      name
      key
      url
      mimetype
      type
      size
      width
      height
      source
      uploading
      file
    }
  `,
  ProjectFull: projectFullFragment,
};

export const UserProjectsQuery = gql`
  query UserProjectsQuery(
    $page: PaginationArgs!
    $order: Order!
    $query: String
  ) {
    userProjects(order: $order, page: $page, query: $query) {
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
      totalCount
      edges {
        node {
          ...ProjectFull
        }
      }
    }
  }
  ${ProjectsFragments.ProjectFull}
`;

export const UserProjectQuery = gql`
  query UserProjectQuery($id: String!) {
    userProject(id: $id) {
      ...ProjectFull
    }
  }
  ${ProjectsFragments.ProjectFull}
`;

export const UserFavoriteProjectsQuery = gql`
  query UserFavoriteProjectsQuery($limit: Int) {
    userFavoriteProjects(limit: $limit) {
      ...ProjectFull
    }
  }
  ${ProjectsFragments.ProjectFull}
`;

export const UpdateFavoriteProjectMutation = gql`
  mutation UpdateFavoriteProjectMutation($id: String!, $favorite: Boolean!) {
    updateFavoriteProject(id: $id, favorite: $favorite) {
      ...ProjectFull
    }
  }
  ${ProjectsFragments.ProjectFull}
`;

export const CreateProjectMutation = gql`
  mutation CreateProjectMutation(
    $name: String!
    $description: String
    $formats: [TemplateFormat!]
    $fps: Int!
  ) {
    createProject(
      data: {
        name: $name
        description: $description
        formats: $formats
        fps: $fps
      }
    ) {
      ...ProjectBasic
    }
  }
  ${ProjectsFragments.ProjectBasic}
`;

export const UserProjectAssetsQuery = gql`
  query UserProjectAssetsQuery(
    $id: String!
    $source: AssetSource!
    $page: PaginationArgs!
    $query: String
  ) {
    userProjectAssets(id: $id, source: $source, page: $page, query: $query) {
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
      totalCount
      edges {
        node {
          ...ProjectAsset
        }
      }
    }
  }
  ${ProjectsFragments.ProjectAsset}
`;

export const UserProjectMediasQuery = gql`
  query UserProjectMediasQuery(
    $id: String!
    $page: PaginationArgs!
    $order: Order!
    $query: String
  ) {
    userProjectMedias(id: $id, order: $order, page: $page, query: $query) {
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
      totalCount
      edges {
        node {
          ...MediaBasicWithExports
        }
      }
    }
  }
  ${MediasFragments.MediaBasicWithExports}
`;

export const AddProjectAssetMutation = gql`
  mutation AddProjectAssetMutation(
    $externalUrl: String
    $file: Upload
    $projectId: String!
    $source: AssetSource!
    $uiId: String!
  ) {
    addProjectAsset(
      externalUrl: $externalUrl
      file: $file
      projectId: $projectId
      source: $source
      uiId: $uiId
    ) {
      ...ProjectAsset
    }
  }
  ${ProjectsFragments.ProjectAsset}
`;

export const DeleteProjectAssetMutation = gql`
  mutation DeleteProjectAssetMutation(
    $projectId: String!
    $filename: String!
    $key: String!
    $id: String!
  ) {
    deleteProjectAsset(
      filename: $filename
      id: $id
      key: $key
      projectId: $projectId
    ) {
      ...ProjectAsset
    }
  }
  ${ProjectsFragments.ProjectAsset}
`;

// first is required here for pagination params
export const userProjectsQueryVars: QueryUserProjectsArgs = {
  order: { field: 'createdAt', direction: OrderDirection.Desc },
  page: { first: 24 },
};
// first is required here for pagination params
export const userProjectMediasQueryVars: QueryUserProjectMediasArgs = {
  id: '',
  order: { field: 'createdAt', direction: OrderDirection.Desc },
  page: { first: 25 },
};

export const userProjectAssetsQueryVars: QueryUserProjectAssetsArgs = {
  id: '',
  source: AssetSource.Template,
  query: '',
  page: { first: 24, after: null },
};
