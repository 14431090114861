import React, { useMemo } from 'react';
import Link from 'next/link';
import { Menu, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { useAuth } from '../auth/auth';
import { menuRight, menuMain, routePath } from '../_data/menus';
import clsx from 'clsx';
import NavMobile from './nav-mobile';
import { infos } from '../_data/infos';
import { CustomLink } from '../custom-link/custom-link';
import { BreadCrumb } from '../breadcrumb/breadcrumb';

export const Nav: React.FC = () => {
  const { user, isLoading, isAuthenticated, scope } = useAuth();
  const { pathname, query } = useRouter();

  const homeLink = useMemo(() => {
    if (!isAuthenticated) {
      return '/';
    }
    if (query?.workspaceId) {
      return `/${scope}`;
    }
    return routePath.dashboard;
  }, [isAuthenticated, query?.workspaceId, scope]);

  return (
    <nav className="bg-gray-800 select-none">
      <div
        className={clsx(
          'relative  mx-auto px-4 sm:px-6 lg:px-8',
          !isAuthenticated && 'max-w-7xl'
        )}
      >
        <div className="flex items-center justify-between h-14">
          <div className="flex flex-1 items-center min-w-0">
            <div>
              <Link href={homeLink}>
                <a
                  href={homeLink}
                  className="block text-gray-300 hover:text-white rounded-md transition-colors focus-lg focus:ring-white"
                >
                  <span className="sr-only">{infos.siteTitle}</span>
                  {isAuthenticated ? (
                    <svg
                      className="h-8 w-8 text-indigo-600"
                      viewBox="0 0 500 500"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-label={`${infos.siteName} Logo`}
                    >
                      <rect
                        width="500"
                        height="500"
                        rx="7%"
                        fill="currentColor"
                      />
                      <path
                        d="M278.039 390.078H222.18V166.055H138V110H362.023V166.055H278.039V390.078Z"
                        fill="white"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="133"
                      height="24"
                      viewBox="0 0 199 36"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-label={`${infos.siteName} Logo`}
                    >
                      <path d="M18 36H10.8201V7.20502H0V0H28.795V7.20502H18V36Z" />
                      <path d="M48.8033 36H41.6234V24.728C40.0335 24.3264 38.569 23.682 37.2301 22.795C35.908 21.908 34.7699 20.8536 33.8159 19.6318C32.8619 18.3933 32.1172 17.0293 31.5816 15.5397C31.0628 14.0335 30.8033 12.4519 30.8033 10.795V0H38.0084V10.795C38.0084 11.7824 38.1925 12.7197 38.5607 13.6067C38.9456 14.477 39.4644 15.2385 40.1172 15.8912C40.7699 16.5439 41.5314 17.0628 42.4017 17.4477C43.2887 17.8159 44.2259 18 45.2134 18C46.2008 18 47.1297 17.8159 48 17.4477C48.887 17.0628 49.6569 16.5439 50.3096 15.8912C50.9623 15.2385 51.4728 14.477 51.841 13.6067C52.2259 12.7197 52.4184 11.7824 52.4184 10.795V0H59.5983V10.795C59.5983 12.4519 59.3305 14.0335 58.795 15.5397C58.2762 17.0293 57.5398 18.3933 56.5858 19.6318C55.6318 20.8536 54.4937 21.908 53.1715 22.795C51.8494 23.682 50.3933 24.3264 48.8033 24.728V36Z" />
                      <path d="M71.6987 7.20502V21.6151H78.9038C79.8912 21.6151 80.8201 21.431 81.6904 21.0628C82.5607 20.6778 83.3222 20.159 83.9749 19.5063C84.6276 18.8536 85.1381 18.0921 85.5063 17.2218C85.8912 16.3347 86.0837 15.3975 86.0837 14.41C86.0837 13.4226 85.8912 12.4937 85.5063 11.6234C85.1381 10.7364 84.6276 9.96653 83.9749 9.31381C83.3222 8.66109 82.5607 8.15063 81.6904 7.78243C80.8201 7.39749 79.8912 7.20502 78.9038 7.20502H71.6987ZM71.6987 36H64.4937V0H78.9038C80.2259 0 81.4979 0.175732 82.7197 0.527197C83.9414 0.861925 85.0795 1.34728 86.1339 1.98326C87.205 2.60251 88.1757 3.35565 89.046 4.24268C89.9331 5.11297 90.6862 6.08368 91.3054 7.15481C91.9414 8.22594 92.4268 9.37239 92.7615 10.5941C93.113 11.8159 93.2887 13.0879 93.2887 14.41C93.2887 16.3849 92.9121 18.251 92.159 20.0084C91.4059 21.749 90.3766 23.272 89.0711 24.5774C87.7657 25.8828 86.2343 26.9121 84.477 27.6653C82.7364 28.4184 80.8787 28.795 78.9038 28.795H71.6987V36Z" />
                      <path d="M122.209 36H97.5314V0H122.209V7.20502H104.736V14.41H116.561V21.6151H104.736V28.795H122.209V36Z" />
                      <path d="M154.77 0L142.946 36H135.741L123.967 0H132.151L139.331 24.0251L146.536 0H154.77Z" />
                      <path d="M165.439 36H158.234V0H165.439V36Z" />
                      <path d="M170.033 10.795C170.033 9.30544 170.318 7.90795 170.887 6.60251C171.456 5.29707 172.226 4.159 173.197 3.18828C174.184 2.20084 175.331 1.42259 176.636 0.853557C177.941 0.284519 179.339 0 180.828 0H197.347V7.20502H180.828C180.326 7.20502 179.858 7.29707 179.423 7.48117C178.987 7.66527 178.603 7.92469 178.268 8.25941C177.95 8.57741 177.699 8.95398 177.515 9.38912C177.331 9.82427 177.238 10.2929 177.238 10.795C177.238 11.2971 177.331 11.7741 177.515 12.2259C177.699 12.6611 177.95 13.046 178.268 13.3808C178.603 13.6987 178.987 13.9498 179.423 14.1339C179.858 14.318 180.326 14.41 180.828 14.41H188.033C189.523 14.41 190.921 14.6946 192.226 15.2636C193.548 15.8159 194.695 16.5858 195.665 17.5732C196.653 18.5439 197.423 19.6904 197.975 21.0126C198.544 22.318 198.828 23.7155 198.828 25.205C198.828 26.6946 198.544 28.0921 197.975 29.3975C197.423 30.7029 196.653 31.8494 195.665 32.8368C194.695 33.8075 193.548 34.5774 192.226 35.1464C190.921 35.7155 189.523 36 188.033 36H172.042V28.795H188.033C188.536 28.795 189.004 28.7029 189.439 28.5188C189.874 28.3347 190.251 28.0837 190.569 27.7657C190.904 27.431 191.163 27.046 191.347 26.6109C191.531 26.1757 191.623 25.7071 191.623 25.205C191.623 24.7029 191.531 24.2343 191.347 23.7992C191.163 23.364 190.904 22.9874 190.569 22.6695C190.251 22.3347 189.874 22.0753 189.439 21.8912C189.004 21.7071 188.536 21.6151 188.033 21.6151H180.828C179.339 21.6151 177.941 21.3305 176.636 20.7615C175.331 20.1925 174.184 19.4226 173.197 18.4519C172.226 17.4644 171.456 16.318 170.887 15.0126C170.318 13.6904 170.033 12.2845 170.033 10.795Z" />
                    </svg>
                  )}
                </a>
              </Link>
            </div>
            <div className="flex-1 min-w-0">
              {!isAuthenticated ? (
                <div className="flex-1 hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {menuMain
                      .filter((item) => item.protected === !!user)
                      .map((item) => (
                        <Link key={item.id} href={item.link}>
                          <a
                            href={item.link}
                            className={clsx(
                              'px-3 py-2 rounded-md text-sm focus-lg focus:ring-white',
                              pathname.startsWith(item.link)
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                            )}
                          >
                            {item.name}
                          </a>
                        </Link>
                      ))}
                  </div>
                </div>
              ) : (
                <BreadCrumb />
              )}
            </div>
          </div>
          <div className="flex-shrink-0">
            {!isLoading && (
              <div className="ml-auto">
                <div className="hidden md:block">
                  {user ? (
                    <div className="flex items-center">
                      {/** <!-- Profile dropdown --> */}
                      <div className="relative z-50">
                        <Menu>
                          {({ open }) => (
                            <>
                              <Menu.Button
                                className={clsx(
                                  'inline-flex justify-center items-center p-2 text-sm font-medium leading-5',
                                  'hover:text-white rounded-md transition duration-150 ease-in-out focus-lg focus:ring-white',
                                  open ? 'text-white' : 'text-gray-400'
                                )}
                                title={
                                  open ? 'close user menu' : 'open user menu'
                                }
                              >
                                <svg
                                  className="h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                </svg>
                                <svg
                                  className="w-5 h-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </Menu.Button>
                              <Transition
                                show={open}
                                enter="ease-out duration-100"
                                enterFrom="transform opacity-0"
                                enterTo="transform opacity-100"
                                leave="ease-in duration-75"
                                leaveFrom="transform opacity-100"
                                leaveTo="transform opacity-0"
                              >
                                <Menu.Items
                                  static
                                  className="absolute right-0 w-56 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                                >
                                  <div className="px-4 py-3">
                                    <p className="text-sm font-medium  text-gray-900">
                                      {user.firstName} {user.lastName}
                                    </p>
                                    <p className="text-sm leading-4 truncate">
                                      {user.email}
                                    </p>
                                  </div>

                                  <div className="py-1">
                                    {menuRight
                                      .filter(
                                        (item) => item.protected === !!user
                                      )
                                      .map((item) => (
                                        <Menu.Item key={item.id}>
                                          {({ active }) => (
                                            <CustomLink
                                              href={item.link}
                                              className={clsx(
                                                'flex justify-between w-full px-4 py-2 text-sm leading-5 text-left focus-lg focus:ring-white',
                                                active
                                                  ? 'bg-gray-100 text-gray-900'
                                                  : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                                              )}
                                            >
                                              {item.name}
                                            </CustomLink>
                                          )}
                                        </Menu.Item>
                                      ))}
                                  </div>
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <CustomLink
                                          href={routePath.logout}
                                          className={clsx(
                                            'flex justify-between w-full px-4 py-2 text-sm leading-5 text-left',
                                            active
                                              ? 'bg-gray-100 text-gray-900'
                                              : 'text-gray-700'
                                          )}
                                        >
                                          Sign out
                                        </CustomLink>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu>
                      </div>
                    </div>
                  ) : (
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        <Link href={routePath.login}>
                          <a
                            href={routePath.login}
                            className={`px-3 py-2 rounded-md text-sm select-none focus-lg focus:ring-white ${
                              routePath.login === pathname
                                ? 'text-gray-400'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                            }`}
                          >
                            Login
                          </a>
                        </Link>
                        <Link href={routePath.signup}>
                          <a
                            href={routePath.signup}
                            className={`px-3 py-2 rounded-md text-sm font-medium select-none focus-lg focus:ring-white ${
                              routePath.signup === pathname
                                ? 'bg-gray-900 text-white'
                                : 'text-white bg-indigo-600 hover:bg-indigo-700'
                            }`}
                          >
                            Join Beta
                          </a>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                <NavMobile />
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
