import { gql } from '@apollo/client';
import { UsersFragments } from './users';

export const LoginMutation = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      accessToken
      refreshToken
      user {
        ...UserBasic
      }
    }
  }
  ${UsersFragments.UserBasic}
`;

export const SignupMutation = gql`
  mutation SignupMutation(
    $firstName: String
    $lastName: String
    $email: String!
    $password: String!
  ) {
    signup(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
      }
    ) {
      accessToken
      refreshToken
      user {
        ...UserBasic
      }
    }
  }
  ${UsersFragments.UserBasic}
`;
