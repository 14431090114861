export const transitionProps = {
  loading: {
    enter: 'ease-out duration-100',
    enterFrom: 'opacity-0',
    enterTo: 'opacity-100',
    leave: 'ease-in duration-100',
    leaveFrom: 'opacity-100',
    leaveTo: 'opacity-0',
  },
  loaded: {
    enter: 'ease-out duration-300',
    enterFrom: 'opacity-0',
    enterTo: 'opacity-100',
    leave: 'ease-in duration-300',
    leaveFrom: 'opacity-100',
    leaveTo: 'opacity-0',
  },
};

export const transitionShortProps = {
  loading: {
    enter: 'ease-out duration-100',
    enterFrom: 'opacity-0',
    enterTo: 'opacity-100',
    leave: 'ease-in duration-100 delay-300',
    leaveFrom: 'opacity-100',
    leaveTo: 'opacity-0',
  },
  loaded: {
    enter: 'ease-out duration-300 delay-300',
    enterFrom: 'opacity-0',
    enterTo: 'opacity-100',
    leave: 'ease-in duration-300',
    leaveFrom: 'opacity-100',
    leaveTo: 'opacity-0',
  },
};

export const windowSliderStyleProps = {
  trackStyle: { backgroundColor: 'rgb(107, 114, 128)' },
  handleStyle: {
    border: 'none',
    backgroundColor: 'rgb(107, 114, 128)',
    boxShadow: 'none',
    opacity: 1,
  },
  railStyle: {
    backgroundColor: 'rgba(107, 114, 128, 0.7)',
  },
};

export const windowSliderSmallStyleProps = {
  trackStyle: { ...windowSliderStyleProps.trackStyle, height: '2px' },
  handleStyle: {
    ...windowSliderStyleProps.handleStyle,
    height: '12px',
    width: '12px',
  },
  railStyle: { ...windowSliderStyleProps.railStyle, height: '2px' },
};
