import React from 'react';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { positions, Provider as AlertProvider } from 'react-alert';
import { AuthProvider, Alert, BreadCrumbProvider } from '@typevid/ui-shared';
import { ErrorBoundary } from '@sentry/nextjs';
import { CustomPageProps, useApollo } from '../lib/apollo-client';
import './styles.css';

const FallbackComponent: React.FC<{
  error: Error;
  componentStack: string;
  eventId: string;
  resetError: () => void;
}> = ({ error, componentStack, resetError }) => {
  return (
    <div className="max-w-7xl mx-auto py-6 px-6 lg:px-8">
      <div className="">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={resetError}
        >
          Close
        </button>
      </div>
      <div className="sm:py-5 sm:px-10 mt-6 py-2 px-4 bg-white overflow-auto border-l-4 sm:border-l-8 border-red-500 rounded-md shadow">
        <div className="font-bold text-xl">You have encountered an error</div>
        <div className="text-red-500">{error.toString()}</div>
        <pre className="my-4 overflow-auto h-56 rounded-md bg-gray-100">
          {componentStack}
        </pre>
      </div>
    </div>
  );
};

function CustomApp({ Component, pageProps }: AppProps<CustomPageProps>) {
  const apolloClient = useApollo(pageProps);

  return (
    <ErrorBoundary
      showDialog={true}
      fallback={(props) => <FallbackComponent {...props} />}
    >
      <ApolloProvider client={apolloClient}>
        <AuthProvider apolloClient={apolloClient}>
          <AlertProvider
            template={Alert}
            position={positions.TOP_LEFT}
            timeout={5000}
          >
            <BreadCrumbProvider>
              <Component {...pageProps} />
            </BreadCrumbProvider>
          </AlertProvider>
        </AuthProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default CustomApp;
