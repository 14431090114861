import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import Link from 'next/link';
import { del } from 'object-path-immutable';
import React, { useMemo } from 'react';
import { useAuth } from '../auth/auth';
import { useBreadcrumbSelector } from './breadcrumb.context';

import { CustomLink } from '../custom-link/custom-link';
import { routePath } from '../_data';

interface ScopeSwitcherProps {
  href: string;
  single: boolean;
}
export const ScopeSwitcher: React.FC<ScopeSwitcherProps> = React.memo(
  ({ href, single }) => {
    const { user, scope } = useAuth();
    const personalAccount = {
      id: '0',
      name: 'Ossama Benallouch',
      href: routePath.dashboard,
    };
    const teams = [
      { id: '1', name: 'Typevis', href: `/${scope}` },
      { id: '2', name: 'Trenquest', href: `/${scope}` },
      { id: '3', name: 'Facebook Ads', href: `/${scope}` },
    ];

    return (
      <div className="flex items-center justify-center h-full space-x-2">
        <Link href={href}>
          <a
            href={href}
            className="text-sm font-medium text-gray-400 flex items-center justify-center space-x-2 max-w-[30ch] truncate hover:text-gray-200 focus-lg focus:ring-white"
          >
            <span className="w-8 h-8 rounded-full flex-shrink-0 bg-gray-700 border border-gray-600">
              <img alt="" />
            </span>
            <span
              className={clsx('truncate ', !single && 'hidden sm:inline-block')}
            >
              {user?.firstName} {user?.lastName}
            </span>
          </a>
        </Link>

        <div className="relative z-90">
          <Menu>
            {({ open }) => (
              <>
                <div className="flex items-center">
                  <Menu.Button
                    className="text-gray-400 hover:bg-gray-700 rounded-sm py-1 focus-lg focus:ring-white"
                    title={
                      open ? 'close scope switcher' : 'open scope switcher'
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 pointer-events-none"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden={true}
                    >
                      <path d="M0 0h24v24H0V0z" fill="none"></path>
                      <path d="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z"></path>
                    </svg>
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  enter="ease-out duration-100"
                  enterFrom="transform opacity-0 translate-y-2"
                  enterTo="transform opacity-100 translate-y-0"
                  leave="ease-in duration-75"
                  leaveFrom="transform opacity-100 translate-y-2"
                  leaveTo="transform opacity-0 translate-y-0"
                >
                  <Menu.Items className="absolute w-52 mt-2 -mr-2 right-auto sm:right-0 -left-24 sm:left-auto origin-center border border-gray-300 bg-white divide-y divide-gray-100 rounded-md shadow-lg outline-none">
                    <div className="py-3">
                      <p className="px-4 mb-1 text-xs text-gray-500">Account</p>
                      <Menu.Item>
                        {({ active }) => (
                          <CustomLink
                            href={personalAccount.href}
                            onClick={() => false}
                            className={clsx(
                              'flex justify-between w-full px-4 py-1.5',
                              active ? 'bg-gray-100' : 'text-gray-700'
                            )}
                          >
                            <span className="inline-flex space-x-2 items-center truncate">
                              <span className="w-6 h-6 rounded-full bg-gray-300 flex-shrink-0">
                                <img alt="" />
                              </span>
                              <span className="truncate text-sm">
                                {personalAccount.name}
                              </span>
                            </span>
                          </CustomLink>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-3">
                      <p className="px-4 mb-1 text-xs text-gray-500">Teams</p>

                      {teams.map(({ name, id, href }) => (
                        <Menu.Item key={id}>
                          {({ active }) => (
                            <CustomLink
                              href={href}
                              onClick={() => false}
                              className={clsx(
                                'flex justify-between w-full px-4 py-1.5',
                                active ? 'bg-gray-100' : 'text-gray-700'
                              )}
                            >
                              <span className="inline-flex space-x-2 items-center truncate">
                                <span className="w-6 h-6 rounded-full bg-gray-300 flex-shrink-0">
                                  <img alt="" />
                                </span>
                                <span className="truncate text-sm">{name}</span>
                              </span>
                            </CustomLink>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    );
  }
);

interface BreadCrumbItemProps {
  isCurrent?: boolean;
  children?: React.ReactNode;
}
export const BreadCrumbItem: React.FC<BreadCrumbItemProps> = React.memo(
  ({ children, isCurrent = false }) => {
    return (
      <div
        aria-current={isCurrent ? 'page' : undefined}
        className="flex items-center space-x-1 md:space-x-3"
      >
        <svg
          className="w-6 h-6 text-gray-600"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
        {children}
      </div>
    );
  }
);

const useBreadcrumbData = () => {
  const data = useBreadcrumbSelector((s) => s.data);

  const { scopeSwitch, items, size } = useMemo(() => {
    return {
      scopeSwitch: data?.[0] ?? null,
      items: data ? del(data, '0') : null,
      size: Object.keys(data ?? {}).length,
    };
  }, [data]);

  return { scopeSwitch, items, size };
};
const BreadCrumbComponent: React.FC = () => {
  const show = useBreadcrumbSelector((s) => s.show);
  const { scopeSwitch, items, size } = useBreadcrumbData();

  if (!show) {
    return null;
  }
  return (
    <div className="flex items-center">
      <div className="ml-1 md:ml-3">
        {scopeSwitch && (
          <BreadCrumbItem isCurrent={size === 1}>
            <ScopeSwitcher href={scopeSwitch.href} single={size <= 1} />
          </BreadCrumbItem>
        )}
      </div>
      <div className="mx-1 md:mx-3 w-full max-w-full overflow-hidden">
        <div className="relative">
          <span className="w-4 absolute left-0 inset-y-0 bg-gradient-to-r from-gray-800" />
          <div className="flex space-x-1 md:space-x-3 md:[&>div:last-child]:pr-3 [&>div:last-child]:pr-1 overflow-auto no-scrollbar">
            {items &&
              Object.entries(items).map(([k, v], i) => (
                <BreadCrumbItem key={k} isCurrent={i === size - 2}>
                  {i === size - 2 ? (
                    <span className="text-sm font-medium text-gray-400 max-w-[30ch] truncate rounded-sm focus:outline-none cursor-default">
                      {v.label}
                    </span>
                  ) : (
                    <Link href={v.href}>
                      <a
                        href={v.href}
                        className="text-sm font-medium text-gray-400 max-w-[30ch] truncate hover:text-gray-200 focus-lg focus:ring-white"
                      >
                        {v.label}
                      </a>
                    </Link>
                  )}
                </BreadCrumbItem>
              ))}
          </div>
          <span className="w-4 absolute right-0 inset-y-0 bg-gradient-to-l from-gray-800" />
        </div>
      </div>
    </div>
  );
};

export const BreadCrumb = React.memo(BreadCrumbComponent);
export default BreadCrumbComponent;
