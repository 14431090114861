import React from 'react';
import Head from 'next/head';
import { Nav } from '../nav/nav';
import { infos } from '../_data';
import { Footer } from '../footer/footer';

interface LayoutProps {
  children: React.ReactNode;
  title: string;
  description?: string;
  header?: React.ReactNode;
  showFooter?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  title,
  description = infos.siteDescription,
  header = null,
  showFooter = false,
}) => {
  return (
    <>
      <Head>
        <title>{`${title} - ${infos.siteName}`}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/default/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/default/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/default/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/default/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicon/default/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <link rel="shortcut icon" href="/favicon/default/favicon.ico" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta
          name="msapplication-config"
          content="/favicon/default/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <div>
        <Nav />
        {header}
        <main style={{ minHeight: 'calc(100vh - 3.5rem)' }}>{children}</main>
        {showFooter && <Footer />}
      </div>
    </>
  );
};

export default Layout;
