import React, { HTMLProps } from 'react';
import Link from 'next/link';

export interface CustomLinkProps extends HTMLProps<HTMLAnchorElement> {
  href: string;
}

const CustomLinkComponent = React.forwardRef<
  HTMLAnchorElement,
  CustomLinkProps
>(({ href, children, ...rest }, ref) => {
  return (
    <Link href={href}>
      <a ref={ref} {...rest}>
        {children}
      </a>
    </Link>
  );
});

export const CustomLink = React.memo(CustomLinkComponent);
export default CustomLinkComponent;
