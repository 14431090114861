import { ExportFileItem, exportsFileTypes } from '@typevid/shared-data';

export const exportsFileTypesIcons = {
  mp4: '<svg xmlns="http://www.w3.org/2000/svg" height="100%"  width="100%" viewBox="0 0 24 24" fill="currentColor"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><polygon points="9.5,7.5 9.5,16.5 16.5,12"/><path d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18.01H4V5.99h16V18.01z"/></g></g></svg>',
  mov: '<svg xmlns="http://www.w3.org/2000/svg" height="100%"  width="100%" viewBox="0 0 24 24" fill="currentColor"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><polygon points="9.5,7.5 9.5,16.5 16.5,12"/><path d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18.01H4V5.99h16V18.01z"/></g></g></svg>',
  webm: '<svg xmlns="http://www.w3.org/2000/svg" height="100%"  width="100%" viewBox="0 0 24 24" fill="currentColor"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><polygon points="9.5,7.5 9.5,16.5 16.5,12"/><path d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18.01H4V5.99h16V18.01z"/></g></g></svg>',
  gif: '<svg xmlns="http://www.w3.org/2000/svg" height="100%"  width="100%" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M15 2c-2.71 0-5.05 1.54-6.22 3.78-1.28.67-2.34 1.72-3 3C3.54 9.95 2 12.29 2 15c0 3.87 3.13 7 7 7 2.71 0 5.05-1.54 6.22-3.78 1.28-.67 2.34-1.72 3-3C20.46 14.05 22 11.71 22 9c0-3.87-3.13-7-7-7zM9 20c-2.76 0-5-2.24-5-5 0-1.12.37-2.16 1-3 0 3.87 3.13 7 7 7-.84.63-1.88 1-3 1zm3-3c-2.76 0-5-2.24-5-5 0-1.12.37-2.16 1-3 0 3.86 3.13 6.99 7 7-.84.63-1.88 1-3 1zm4.7-3.3c-.53.19-1.1.3-1.7.3-2.76 0-5-2.24-5-5 0-.6.11-1.17.3-1.7.53-.19 1.1-.3 1.7-.3 2.76 0 5 2.24 5 5 0 .6-.11 1.17-.3 1.7zM19 12c0-3.86-3.13-6.99-7-7 .84-.63 1.87-1 3-1 2.76 0 5 2.24 5 5 0 1.12-.37 2.16-1 3z"/><path d="M0 0h24v24H0zm0 0h24v24H0z" fill="none"/></svg>',
  apng: '<svg xmlns="http://www.w3.org/2000/svg" height="100%"  width="100%" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M15 2c-2.71 0-5.05 1.54-6.22 3.78-1.28.67-2.34 1.72-3 3C3.54 9.95 2 12.29 2 15c0 3.87 3.13 7 7 7 2.71 0 5.05-1.54 6.22-3.78 1.28-.67 2.34-1.72 3-3C20.46 14.05 22 11.71 22 9c0-3.87-3.13-7-7-7zM9 20c-2.76 0-5-2.24-5-5 0-1.12.37-2.16 1-3 0 3.87 3.13 7 7 7-.84.63-1.88 1-3 1zm3-3c-2.76 0-5-2.24-5-5 0-1.12.37-2.16 1-3 0 3.86 3.13 6.99 7 7-.84.63-1.88 1-3 1zm4.7-3.3c-.53.19-1.1.3-1.7.3-2.76 0-5-2.24-5-5 0-.6.11-1.17.3-1.7.53-.19 1.1-.3 1.7-.3 2.76 0 5 2.24 5 5 0 .6-.11 1.17-.3 1.7zM19 12c0-3.86-3.13-6.99-7-7 .84-.63 1.87-1 3-1 2.76 0 5 2.24 5 5 0 1.12-.37 2.16-1 3z"/><path d="M0 0h24v24H0zm0 0h24v24H0z" fill="none"/></svg>',
  jpeg: '<svg xmlns="http://www.w3.org/2000/svg" height="100%"  width="100%" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z"/></svg>',
  png: '<svg xmlns="http://www.w3.org/2000/svg" height="100%"  width="100%" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z"/></svg>',
  pdf: '<svg xmlns="http://www.w3.org/2000/svg" height="100%"  width="100%" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"/></svg>',
};
export const exportsFileTypesWithIcons: ExportFileItem[] =
  exportsFileTypes.reduce((acc, v, i) => {
    if (v.id === 'mp4') {
      acc.push({
        id: 'video',
        name: 'Video',
        value: 'video',
        unit: 'none',
        cpu: 0,
        costUnit: '',
        mimeType: '',
        extension: 'mp4',
        description: '',
        defaultSettings: {},
      });
    }
    if (v.id === 'gif') {
      acc.push({
        id: 'animation',
        name: 'Animation',
        value: 'animation',
        unit: 'none',
        cpu: 0,
        costUnit: '',
        mimeType: '',
        extension: 'gif',
        description: '',
        defaultSettings: {},
      });
    }
    if (v.id === 'jpeg') {
      acc.push({
        id: 'static',
        name: 'Static',
        value: 'static',
        unit: 'none',
        cpu: 0,
        costUnit: '',
        mimeType: '',
        extension: 'jpeg',
        description: '',
        defaultSettings: {},
      });
    }
    acc.push({
      ...v,
      icon:
        exportsFileTypesIcons?.[v.id as keyof typeof exportsFileTypesIcons] ??
        '',
    });
    return acc;
  }, [] as ExportFileItem[]);
