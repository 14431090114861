import { nanoid } from 'nanoid';

export const EXPORT_DEFAULTS = {
  mp4Fps: 30,
  mp4Quality: 80,
  webmTransparent: false,
  gifFps: 15,
  gifQuality: 80,
  gifTransparent: false,
  jpegQuality: 80,
  pngTransparent: false,
  pdfBleed: false,
};

export const TEMPLATE_STYLES_DEFAULT = [
  {
    id: 'MAIN',
    value: 'section',
    label: 'Main (All Scenes)',
    type: 'TAG',
    style: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.2,
      fontFamily: 'Noto Sans',
      color: '#212121',
      minHeight: '100vh',
      height: '100vh',
      width: '100vw',
      minWidth: '100vw',
      overflow: 'hidden',
    },
    multiStyle: {
      fontFamily: {
        family: 'Noto Sans',
        variants: ['400', '400italic', '700', '700italic'],
      },
    },
  },
  {
    id: 'BLOCK',
    value: 'div',
    label: 'All Blocks',
    type: 'TAG',
    style: {},
  },
  {
    id: 'HEADING',
    value: 'h1',
    label: 'All Headings',
    type: 'TAG',
    style: {
      fontSize: '48px',
      fontWeight: 700,
    },
  },
  {
    id: 'PARAGRAPH',
    value: 'p',
    label: 'All Paragraphs',
    type: 'TAG',
    style: {
      fontSize: '32px',
    },
  },
  {
    id: 'IMAGE',
    value: 'img',
    label: 'All Images',
    type: 'TAG',
    style: {},
  },
  {
    id: 'LIST',
    value: 'ul',
    label: 'All Lists',
    type: 'TAG',
    style: {},
  },
  {
    id: 'LIST_ITEM',
    value: 'li',
    label: 'All List Items',
    type: 'TAG',
    style: {},
  },
  {
    id: 'BLOCK_QUOTE',
    value: 'blockquote',
    label: 'All Block Quotes',
    type: 'TAG',
    style: {
      fontSize: '32px',
      paddingLeft: '32px',
      paddingRight: '32px',
      paddingTop: '16px',
      paddingBottom: '16px',
      borderWidth: 'unset unset unset 8px',
      borderColor: 'unset unset unset #CCCCCC',
      borderStyle: 'unset unset unset solid',
    },
  },
];

export const TEMPLATE_SCENES_DEFAULT = [
  {
    id: nanoid(),
    active: true,
    name: 'Intro',
    type: 'BASE',
    api: {
      id: 'Intro',
      description: '',
    },
    elements: [
      {
        children: [],
        id: nanoid(),
        name: 'Main',
        selectors: { tag: 'MAIN' },
        settings: {},
        type: 'MAIN',
      },
    ],
  },
];
