import { gql } from '@apollo/client';
import { OrderDirection, QueryUserMediaExportsArgs } from './generated/graphql';

const mediaExportTinyFragment = gql`
  fragment MediaExportTiny on MediaExportTiny {
    id
    status
    createdAt
    updatedAt
  }
`;
const mediaExportFragment = gql`
  fragment MediaExport on MediaExport {
    createdAt
    updatedAt
    createdBy
    credits
    error
    exportedAt
    fileType
    fileSettings
    format
    height
    width
    id
    jobId
    key
    url
    mediaId
    mimetype
    quantity
    scenes
    size
    source
    status
    media {
      id
      title
      project {
        id
        name
      }
    }
    creator {
      id
      firstName
      lastName
    }
  }
`;

const mediaFullFragment = gql`
  fragment MediaFull on Media {
    title
    id
    mode
    source
    createdAt
    updatedAt
    exportedAt
    templateId
    scenes
    theme
    creator {
      id
      firstName
      lastName
    }
    project {
      id
      name
    }
    exports {
      ...MediaExportTiny
    }
  }
  ${mediaExportTinyFragment}
`;

const mediaBasicFragment = gql`
  fragment MediaBasic on Media {
    title
    id
    mode
    source
    createdAt
    updatedAt
    project {
      id
      name
    }
    creator {
      id
      firstName
      lastName
    }
  }
`;

const mediaBasicWithExportsFragment = gql`
  fragment MediaBasicWithExports on Media {
    ...MediaBasic
    exports {
      ...MediaExportTiny
    }
  }
  ${mediaBasicFragment}, ${mediaExportTinyFragment}
`;

export const MediasFragments = {
  MediaBasic: mediaBasicFragment,
  MediaBasicWithExports: mediaBasicWithExportsFragment,
  MediaFull: mediaFullFragment,
  MediaExport: mediaExportFragment,
  MediaExportTiny: mediaExportTinyFragment,
};

export const UserMediaQuery = gql`
  query UserMediaQuery($id: String!) {
    userMedia(id: $id) {
      ...MediaFull
    }
  }
  ${MediasFragments.MediaFull}
`;

export const UserMediaExportsQuery = gql`
  query UserMediaExportsQuery(
    $id: String!
    $page: PaginationArgs!
    $order: Order!
  ) {
    userMediaExports(id: $id, order: $order, page: $page) {
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
      totalCount
      edges {
        node {
          ...MediaExport
        }
      }
    }
  }
  ${MediasFragments.MediaExport}
`;

export const UserMediaExportQuery = gql`
  query UserMediaExport($id: String!) {
    userMediaExport(id: $id) {
      ...MediaExport
    }
  }
  ${MediasFragments.MediaExport}
`;

export const RecentMediasQuery = gql`
  query RecentMediasQuery($projectId: String) {
    recentMedias(projectId: $projectId) {
      ...MediaBasic
    }
  }
  ${MediasFragments.MediaBasic}
`;

export const RecentExportsQuery = gql`
  query RecentExportsQuery($projectId: String) {
    recentExports(projectId: $projectId) {
      ...MediaExport
    }
  }
  ${MediasFragments.MediaExport}
`;
export const CreateMediaMutation = gql`
  mutation CreateMediaMutation($title: String!, $projectId: String!) {
    createMedia(data: { title: $title, projectId: $projectId }) {
      ...MediaFull
    }
  }
  ${MediasFragments.MediaFull}
`;

export const UpdateMediaMutation = gql`
  mutation UpdateMediaMutation(
    $id: String!
    $title: String
    $scenes: JSONObject
    $theme: JSONObject
  ) {
    updateMedia(
      id: $id
      data: { title: $title, scenes: $scenes, theme: $theme }
    ) {
      ...MediaFull
    }
  }
  ${MediasFragments.MediaFull}
`;

export const DuplicateMediaMutation = gql`
  mutation DuplicateMediaMutation($mediaId: String!) {
    duplicateMedia(data: { mediaId: $mediaId }) {
      ...MediaFull
    }
  }
  ${MediasFragments.MediaFull}
`;

export const DeleteMediaMutation = gql`
  mutation DeleteMediaMutation($id: String!) {
    deleteMedia(id: $id) {
      ...MediaFull
    }
  }
  ${MediasFragments.MediaFull}
`;

export const ExportMediaMutation = gql`
  mutation ExportMediaMutation($mediaId: String!, $files: [ExportFile!]!) {
    exportMedia(data: { mediaId: $mediaId, files: $files }) {
      ...MediaExport
    }
  }
  ${MediasFragments.MediaExport}
`;

// first is required here for pagination params
export const userMediaExportsQueryVars: QueryUserMediaExportsArgs = {
  id: '',
  order: { field: 'createdAt', direction: OrderDirection.Desc },
  page: { first: 25 },
};
