import { TemplateFormat } from '@typevid/graphql';

export const defaultFormatsGroups = [
  { id: 'screen', name: 'Screen' },
  { id: 'socialMedia', name: 'Social' },
  { id: 'socialMediaAds', name: 'Social Ads' },
  { id: 'displayAds', name: 'Display Ads' },
  { id: 'coversAndHeaders', name: 'Covers' },
  { id: 'print', name: 'Print' },
  { id: 'other', name: 'Other' },
];
export const defaultFormats: Array<TemplateFormat & { groupId: string }> = [
  {
    id: '1920x1080px',
    groupId: 'screen',
    name: 'Landscape FHD',
    sizeLabel: '1920 x 1080 px',
    width: 1920,
    height: 1080,
    orientation: 0,
  },
  {
    id: '1280x720px',
    groupId: 'screen',
    name: 'Landscape HD',
    sizeLabel: '1280 x 720 px',
    width: 1280,
    height: 720,
    orientation: 0,
  },
  {
    id: '1080x1920px',
    groupId: 'screen',
    name: 'Portrait FHD',
    sizeLabel: '1080 x 1920 px',
    width: 1080,
    height: 1920,
    orientation: 1,
  },
  {
    id: '1024x768px',
    groupId: 'screen',
    name: 'XGA',
    sizeLabel: '1024 x 768 px',
    width: 1024,
    height: 768,
    orientation: 0,
  },
  {
    id: '1366x768px',
    groupId: 'screen',
    name: '1366 x 768',
    sizeLabel: '1366 x 768 px',
    width: 1366,
    height: 768,
    orientation: 0,
  },
  {
    id: '1600x900px',
    groupId: 'screen',
    name: '1600 x 900',
    sizeLabel: '1600 x 900 px',
    width: 1600,
    height: 900,
    orientation: 0,
  },
  {
    id: '1080x1080px',
    groupId: 'socialMedia',
    name: 'Instagram Post',
    sizeLabel: '1080 x 1080 px',
    width: 1080,
    height: 1080,
    orientation: 1,
  },
  {
    id: '940x788px',
    groupId: 'socialMedia',
    name: 'Facebook Post',
    sizeLabel: '940 x 788 px',
    width: 940,
    height: 788,
    orientation: 0,
  },
  {
    id: '1024x512px',
    groupId: 'socialMedia',
    name: 'Twitter Post',
    sizeLabel: '1024 x 512 px',
    width: 1024,
    height: 512,
    orientation: 0,
  },
  {
    id: '1000x1500px',
    groupId: 'socialMedia',
    name: 'Pinterest Pin',
    sizeLabel: '1000 x 1500 px',
    width: 1000,
    height: 1500,
    orientation: 1,
  },
  {
    id: '540x810px',
    groupId: 'socialMedia',
    name: 'Tumblr Graphic',
    sizeLabel: '540 x 810 px',
    width: 540,
    height: 810,
    orientation: 1,
  },
  {
    id: '1200x628px',
    groupId: 'socialMediaAds',
    name: 'Facebook Ad',
    sizeLabel: '1200 x 628 px',
    width: 1200,
    height: 628,
    orientation: 0,
  },
  {
    id: '810x450px',
    groupId: 'socialMediaAds',
    name: 'Facebook App Ad',
    sizeLabel: '810 x 450 px',
    width: 810,
    height: 450,
    orientation: 0,
  },
  {
    id: '1584x396px',
    groupId: 'socialMediaAds',
    name: 'LinkedIn Banner',
    sizeLabel: '1584 x 396 px',
    width: 1584,
    height: 396,
    orientation: 0,
  },
  {
    id: '1920x1920px',
    groupId: 'socialMediaAds',
    name: 'LinkedIn Video Ad',
    sizeLabel: '1920 x 1920 px',
    width: 1920,
    height: 1920,
    orientation: 1,
  },
  {
    id: '1600x900px',
    groupId: 'socialMediaAds',
    name: 'Twitter Ad',
    sizeLabel: '1600 x 900 px',
    width: 1600,
    height: 900,
    orientation: 0,
  },
  {
    id: '1080x1350px',
    groupId: 'socialMediaAds',
    name: 'Feed Ad Video Portrait',
    sizeLabel: '1080 x 1350 px',
    width: 1080,
    height: 1350,
    orientation: 1,
  },
  {
    id: '1640x924px',
    groupId: 'coversAndHeaders',
    name: 'Facebook Cover',
    sizeLabel: '1640 x 924 px',
    width: 1640,
    height: 924,
    orientation: 0,
  },
  {
    id: '1024x1024px',
    groupId: 'coversAndHeaders',
    name: 'Facebook Shops Cover',
    sizeLabel: '1024 x 1024 px',
    width: 1024,
    height: 1024,
    orientation: 1,
  },
  {
    id: '2560x1440px',
    groupId: 'coversAndHeaders',
    name: 'Youtube Cover',
    sizeLabel: '2560 x 1440 px',
    width: 2560,
    height: 1440,
    orientation: 0,
  },
  {
    id: '1500x500px',
    groupId: 'coversAndHeaders',
    name: 'Twitter Cover',
    sizeLabel: '1500 x 500 px',
    width: 1500,
    height: 500,
    orientation: 0,
  },
  {
    id: '1410x2250px',
    groupId: 'coversAndHeaders',
    name: 'Book Cover',
    sizeLabel: '1410 x 2250 px',
    width: 1410,
    height: 2250,
    orientation: 1,
  },
  {
    id: '600x200px',
    groupId: 'coversAndHeaders',
    name: 'Email Header',
    sizeLabel: '600 x 200 px',
    width: 600,
    height: 200,
    orientation: 0,
  },
  {
    id: '2240x1260px',
    groupId: 'coversAndHeaders',
    name: 'Blog Banner',
    sizeLabel: '2240 x 1260 px',
    width: 2240,
    height: 1260,
    orientation: 0,
  },
  {
    id: '1200x630px',
    groupId: 'coversAndHeaders',
    name: 'Open Graph Image',
    sizeLabel: '1200 x 630 px',
    width: 1200,
    height: 630,
    orientation: 0,
  },
  {
    id: '800x800px',
    groupId: 'coversAndHeaders',
    name: 'Youtube Channel Logo',
    sizeLabel: '800 x 800 px',
    width: 800,
    height: 800,
    orientation: 1,
  },
  {
    id: '500x500px',
    groupId: 'coversAndHeaders',
    name: 'Logo',
    sizeLabel: '500 x 500 px',
    width: 500,
    height: 500,
    orientation: 1,
  },
  {
    id: '468x60px',
    groupId: 'displayAds',
    name: 'Banner',
    sizeLabel: '468 x 60 px',
    width: 468,
    height: 60,
    orientation: 0,
  },
  {
    id: '234x60px',
    groupId: 'displayAds',
    name: 'Half banner',
    sizeLabel: '234 x 60 px',
    width: 234,
    height: 60,
    orientation: 0,
  },
  {
    id: '320x50px',
    groupId: 'displayAds',
    name: 'Mobile banner',
    sizeLabel: '320 x 50 px',
    width: 320,
    height: 50,
    orientation: 0,
  },
  {
    id: '320x100px',
    groupId: 'displayAds',
    name: 'Large mobile banner',
    sizeLabel: '320 x 100 px',
    width: 320,
    height: 100,
    orientation: 0,
  },
  {
    id: '120x240px',
    groupId: 'displayAds',
    name: 'Vertical banner',
    sizeLabel: '120 x 240 px',
    width: 120,
    height: 240,
    orientation: 1,
  },
  {
    id: '250x250px',
    groupId: 'displayAds',
    name: 'Square',
    sizeLabel: '250 x 250 px',
    width: 250,
    height: 250,
    orientation: 1,
  },
  {
    id: '200x200px',
    groupId: 'displayAds',
    name: 'Small square',
    sizeLabel: '200 x 200 px',
    width: 200,
    height: 200,
    orientation: 1,
  },
  {
    id: '180x150px',
    groupId: 'displayAds',
    name: 'Small rectangle',
    sizeLabel: '180 x 150 px',
    width: 180,
    height: 150,
    orientation: 0,
  },
  {
    id: '300x600px',
    groupId: 'displayAds',
    name: 'Half page',
    sizeLabel: '300 x 600 px',
    width: 300,
    height: 600,
    orientation: 1,
  },
  {
    id: '336x280px',
    groupId: 'displayAds',
    name: 'Large rectangle',
    sizeLabel: '336 x 280 px',
    width: 336,
    height: 280,
    orientation: 0,
  },
  {
    id: '120x600px',
    groupId: 'displayAds',
    name: 'Skyscraper',
    sizeLabel: '120 x 600 px',
    width: 120,
    height: 600,
    orientation: 1,
  },
  {
    id: '160x600px',
    groupId: 'displayAds',
    name: 'Wide skyscraper',
    sizeLabel: '160 x 600 px',
    width: 160,
    height: 600,
    orientation: 1,
  },
  {
    id: '300x250px',
    groupId: 'displayAds',
    name: 'Medium rectangle',
    sizeLabel: '300 x 250 px',
    width: 300,
    height: 250,
    orientation: 0,
  },
  {
    id: '300x1050px',
    groupId: 'displayAds',
    name: 'Portrait',
    sizeLabel: '300 x 1050 px',
    width: 300,
    height: 1050,
    orientation: 1,
  },
  {
    id: '970x250px',
    groupId: 'displayAds',
    name: 'Billboard',
    sizeLabel: '970 x 250 px',
    width: 970,
    height: 250,
    orientation: 0,
  },
  {
    id: '728x90px',
    groupId: 'displayAds',
    name: 'Leaderboard',
    sizeLabel: '728 x 90 px',
    width: 728,
    height: 90,
    orientation: 0,
  },
  {
    id: '970x90px',
    groupId: 'displayAds',
    name: 'Large leaderboard',
    sizeLabel: '970 x 90 px',
    width: 970,
    height: 90,
    orientation: 0,
  },
  {
    id: '21x29.7cm',
    groupId: 'print',
    name: 'A4 Portrait',
    sizeLabel: '21 x 29.7 cm',
    width: 793.701,
    height: 1122.52,
    orientation: 1,
  },
  {
    id: '29.7x21cm',
    groupId: 'print',
    name: 'A4 Landscape',
    sizeLabel: '29.7 x 21 cm',
    width: 1122.52,
    height: 793.701,
    orientation: 0,
  },
  {
    id: '42x59.4cm',
    groupId: 'print',
    name: 'Poster',
    sizeLabel: '42 x 59.4 cm',
    width: 1587.402,
    height: 2245.039,
    orientation: 1,
  },
  {
    id: '59.4x42cm',
    groupId: 'print',
    name: 'Poster Landscape',
    sizeLabel: '59.4 x 42 cm',
    width: 2245.039,
    height: 1587.402,
    orientation: 0,
  },
  {
    id: '8.5x5cm',
    groupId: 'print',
    name: 'Business Card',
    sizeLabel: '8.5 x 5 cm',
    width: 321.26,
    height: 188.976,
    orientation: 0,
  },
  {
    id: '11x8.5in',
    groupId: 'print',
    name: 'Brochure',
    sizeLabel: '11 x 8.5 in',
    width: 1056,
    height: 816,
    orientation: 0,
  },
  {
    id: '14.8x10.5cm',
    groupId: 'print',
    name: 'Card Landscape',
    sizeLabel: '14.8 x 10.5 cm',
    width: 559.37,
    height: 396.85,
    orientation: 0,
  },
  {
    id: '8.5x2.8in',
    groupId: 'print',
    name: 'Ticket',
    sizeLabel: '8.5 x 2.8 in',
    width: 816,
    height: 268.8,
    orientation: 0,
  },
  {
    id: '24x18in',
    groupId: 'print',
    name: 'Yard Sign',
    sizeLabel: '24 x 18 in',
    width: 2304,
    height: 1728,
    orientation: 0,
  },
  {
    id: '800x1200px',
    groupId: 'other',
    name: 'Infographic',
    sizeLabel: '800 x 1200 px',
    width: 800,
    height: 1200,
    orientation: 1,
  },
  {
    id: '25x20cm',
    groupId: 'other',
    name: 'Photo Collage',
    sizeLabel: '25 x 20 cm',
    width: 944.882,
    height: 755.906,
    orientation: 0,
  },
];
