import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { transitionProps } from '../_data/common-props';

interface LayoutHeaderProps {
  title: string;
  titleTag?: React.ReactNode;
  titleIcon?: React.ReactNode;
  loading?: boolean;
  children?: React.ReactNode;
  position?: 'top' | 'bottom';
}

export const LayoutHeader: React.FC<LayoutHeaderProps> = ({
  title,
  titleTag,
  titleIcon,
  loading = false,
  position = 'bottom',
  children,
}) => {
  return (
    <header
      className={clsx(
        'bg-white',
        position === 'bottom' ? 'shadow' : 'border-b border-gray-100'
      )}
    >
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-2">
          <div className="flex space-x-2 flex-1 min-w-0">
            {!loading ? titleIcon : null}
            <h2 className="inline-flex space-x-2 items-center sm:items-start px-0 sm:py-px text-3xl font-medium leading-tight text-gray-900">
              {loading ? (
                <span className="bg-gray-200 text-gray-200 rounded-md animate-pulse">
                  Header Title Loading
                </span>
              ) : (
                <>
                  <Transition {...transitionProps.loaded} show={true} as="span">
                    {title}
                  </Transition>
                  {titleTag}
                </>
              )}
            </h2>
          </div>
          {children}
        </div>
      </div>
    </header>
  );
};

export default LayoutHeader;
