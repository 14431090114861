import React from 'react';

export const Footer: React.FC = () => {
  return (
    <footer className="relative bg-gray-800  overflow-hidden">
      <div className="max-w-7xl mx-auto px-6 lg:px-8 py-8 md:py-10 lg:py-14 xl:py-16">
        <div className="text-sm  text-gray-400">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="space-y-2">
              <p className="text-gray-300 font-medium uppercase">Use cases</p>

              <p>
                <a href="/" className="hover:underline">
                  Ecommerce
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  Real Estate
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  Publishing
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  SMM
                </a>
              </p>
            </div>
            <div className="space-y-2">
              <p className="text-gray-300 font-medium uppercase">Product</p>

              <p>
                <a href="/" className="hover:underline">
                  Designer
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  Editor
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  Cloud Rendering
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  Integrations
                </a>
              </p>
            </div>
            <div className="space-y-2">
              <p className="text-gray-300 font-medium uppercase">Support</p>
              <p>
                <a href="/" className="hover:underline">
                  Pricing
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  Documentation
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  Guides
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  Status
                </a>
              </p>
            </div>
            <div className="space-y-2">
              <p className="text-gray-300 font-medium uppercase">Company</p>
              <p>
                <a href="/" className="hover:underline">
                  About
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  Blog
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  Changelog
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  Terms of service
                </a>
              </p>
              <p>
                <a href="/" className="hover:underline">
                  Privacy policy
                </a>
              </p>
            </div>
          </div>
          <div className="my-4 md:my-5 lg:my-7 xl:my-8 h-px bg-gray-700"></div>
          <div className="text-center">
            <p>© {new Date().getFullYear()} Typevis. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
