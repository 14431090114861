import { gql } from '@apollo/client';

export const TemplatesFragments = {
  TemplateFull: gql`
    fragment TemplateFull on Template {
      createdAt
      updatedAt
      id
      project {
        id
        name
      }
      scenes
      styles
      motions
      settings
      animations
      themes
    }
  `,
};

export const UserTemplateQuery = gql`
  query UserTemplateQuery($id: String!) {
    userTemplate(id: $id) {
      ...TemplateFull
    }
  }
  ${TemplatesFragments.TemplateFull}
`;

export const UpdateTemplateMutation = gql`
  mutation UpdateTemplateMutation(
    $id: String!
    $scenes: JSONObject
    $settings: JSONObject
    $styles: JSONObject
    $motions: JSONObject
    $animations: JSONObject
    $themes: JSONObject
  ) {
    updateTemplate(
      id: $id
      data: {
        scenes: $scenes
        settings: $settings
        styles: $styles
        motions: $motions
        animations: $animations
        themes: $themes
      }
    ) {
      ...TemplateFull
    }
  }
  ${TemplatesFragments.TemplateFull}
`;
