import format from 'date-fns/format';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

const formatDateObject = {
  A: (d: string | Date) => format(new Date(d), 'MMM dd, yyyy'),
  B: (d: string | Date) => format(new Date(d), 'MMM dd, yyyy H:mm:ss'),
  D: (d: string | Date) => {
    return formatDistanceToNowStrict(new Date(d), {
      addSuffix: true,
      roundingMethod: 'ceil',
    });
  },
};
export const formatDate = (
  d: string | Date,
  f: 'A' | 'B' | 'D' = 'A'
): string | null => {
  if (!d) return null;
  return formatDateObject[f]?.(d) ?? null;
};

/**
 * Create a string from series of consecutive numbers
 * @param arr array of number i.e [1,2,6,7,8,9,12]
 * @param prefix array of prefixes to add to result string ['Page', 'Pages']
 * @returns string i.e. "Pages 1-2, 6-9, 12"
 */

export const createConsecutiveStr = (
  arr: number[],
  prefix = ['Scene', 'Scenes']
) => {
  const str = arr
    .reduce((r: number[][], n) => {
      const lastSubArray = r[r.length - 1];
      if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
        r.push([]);
      }
      r[r.length - 1].push(n);
      return r;
    }, [])
    .map((r) => (r.length > 1 ? [r[0], r[r.length - 1]].join('-') : r.join()))
    .join(', ');

  if (arr.length === 0) return '';
  return arr.length > 1 ? `${prefix[1]} ${str} ` : `${prefix[0]} ${str}`;
};
