import { EXPORT_DEFAULTS } from './defaults';

/** Manually copied from @typevid/graphql generated
 * to avoid importing the whole library
 */
export enum MediaExportStatusType {
  Exported = 'EXPORTED',
  Exporting = 'EXPORTING',
  Failed = 'FAILED',
  Submitted = 'SUBMITTED',
}
export type ExportFileSettingsInput = {
  bleed?: string;
  fps?: string;
  quality?: string;
  transparent?: string;
};
/** END manual copy */

export interface ExportFileItem {
  id: string;
  name: string;
  value: string;
  unit: string;
  costUnit: string;
  cpu: number;
  mimeType: string;
  extension: 'jpeg' | 'png' | 'mp4' | 'pdf' | 'gif' | 'webm' | 'mov' | 'apng';
  description: string;
  defaultSettings: ExportFileSettingsInput;
  icon?: string;
  creditMultiplier?: { [t: string]: number };
}

export const exportsFileTypes: ExportFileItem[] = [
  {
    id: 'mp4',
    name: 'MP4',
    value: 'mp4',
    unit: 'second',
    cpu: 0.025, // cost per unit (frame)
    costUnit: 'frame',
    mimeType: 'video/mp4',
    extension: 'mp4',
    description: 'High quality video (H.264)',
    defaultSettings: {
      fps: `${EXPORT_DEFAULTS.mp4Fps}`,
      quality: `${EXPORT_DEFAULTS.mp4Quality}`,
    },
  },
  {
    id: 'mov',
    name: 'MOV',
    value: 'mov',
    unit: 'second',
    cpu: 0.025, // cost per unit (frame)
    costUnit: 'frame',
    creditMultiplier: { transparent: 3 },
    mimeType: 'video/quicktime',
    extension: 'mov',
    description: 'High quality, large file size (ProRes 4444)',
    defaultSettings: {
      fps: `${EXPORT_DEFAULTS.mp4Fps}`,
      quality: `${EXPORT_DEFAULTS.mp4Quality}`,
      transparent: `${EXPORT_DEFAULTS.webmTransparent}`,
    },
  },
  {
    id: 'webm',
    name: 'WEBM',
    value: 'webm',
    unit: 'second',
    cpu: 0.025, // cost per unit (frame)
    costUnit: 'frame',
    creditMultiplier: { transparent: 3 },
    mimeType: 'video/webm',
    extension: 'webm',
    description: 'High quality video (VP9)',
    defaultSettings: {
      fps: `${EXPORT_DEFAULTS.mp4Fps}`,
      quality: `${EXPORT_DEFAULTS.mp4Quality}`,
      transparent: `${EXPORT_DEFAULTS.webmTransparent}`,
    },
  },
  {
    id: 'gif',
    name: 'GIF',
    value: 'gif',
    unit: 'second',
    cpu: 0.025,
    costUnit: 'frame',
    creditMultiplier: { transparent: 3 },
    mimeType: 'image/gif',
    extension: 'gif',
    description: 'Large file size animation',
    defaultSettings: {
      fps: `${EXPORT_DEFAULTS.gifFps}`,
      quality: `${EXPORT_DEFAULTS.gifQuality}`,
      transparent: `${EXPORT_DEFAULTS.gifTransparent}`,
    },
  },
  {
    id: 'apng',
    name: 'APNG',
    value: 'apng',
    unit: 'second',
    cpu: 0.075,
    costUnit: 'frame',
    mimeType: 'image/apng',
    extension: 'apng',
    description: 'Small file size animation',
    defaultSettings: {
      fps: `${EXPORT_DEFAULTS.gifFps}`,
      transparent: `${EXPORT_DEFAULTS.gifTransparent}`,
    },
  },

  {
    id: 'jpeg',
    name: 'JPEG',
    value: 'jpeg',
    unit: 'image',
    cpu: 1,
    costUnit: 'image',
    mimeType: 'image/jpeg',
    extension: 'jpeg',
    description: 'Small file size image',
    defaultSettings: { quality: `${EXPORT_DEFAULTS.jpegQuality}` },
  },
  {
    id: 'png',
    name: 'PNG',
    value: 'png',
    unit: 'image',
    cpu: 3,
    costUnit: 'image',
    mimeType: 'image/png',
    extension: 'png',
    description: 'High quality image',
    defaultSettings: { transparent: `${EXPORT_DEFAULTS.pngTransparent}` },
  },
  {
    id: 'pdf',
    name: 'PDF',
    value: 'pdf',
    unit: 'page',
    cpu: 3,
    costUnit: 'page',
    mimeType: 'application/pdf',
    extension: 'pdf',
    description: 'High quality, multi-page document',
    defaultSettings: { bleed: `${EXPORT_DEFAULTS.pdfBleed}` },
  },
];

interface ExportInstanceType {
  id: string;
  name: string;
  maxWidth: number;
  maxHeight: number;
  totalDims: number; // maxWidth * maxHeight
  container: { vcpus: string; memory: string };
  creditMultiplier: number;
}

//const USD_PER_CREDIT = 0.02;
const EXPORT_INSTANCE_TYPES: ExportInstanceType[] = [
  {
    id: 'small',
    name: 'Small',
    maxWidth: 640,
    maxHeight: 640,
    totalDims: 409600,
    container: { vcpus: '1', memory: '2048' },
    creditMultiplier: 1,
  },
  {
    id: 'medium',
    name: 'Medium',
    maxWidth: 1280,
    maxHeight: 1280,
    totalDims: 1638400,
    container: { vcpus: '2', memory: '4096' },
    creditMultiplier: 2,
  },
  {
    id: 'large',
    name: 'Large',
    maxWidth: 1920,
    maxHeight: 1920,
    totalDims: 3686400,
    container: { vcpus: '4', memory: '8192' },
    creditMultiplier: 3,
  },
  {
    id: 'xlarge',
    name: 'xLarge',
    maxWidth: 3840,
    maxHeight: 3840,
    totalDims: 14745600,
    container: { vcpus: '4', memory: '16384' },
    creditMultiplier: 4,
  },
  {
    id: 'xxlarge',
    name: 'xxLarge',
    maxWidth: 5000,
    maxHeight: 5000,
    totalDims: 25000000,
    container: { vcpus: '8', memory: '32768' },
    creditMultiplier: 5,
  },
];

export const getExportInstanceType = (
  width: number,
  height: number
): ExportInstanceType => {
  if (!width || !height) {
    throw Error('Width or height not specified');
  }
  const dims = width * height;
  const instanceType = EXPORT_INSTANCE_TYPES.find((f) => dims <= f.totalDims);
  const lastInstanceType =
    EXPORT_INSTANCE_TYPES[EXPORT_INSTANCE_TYPES.length - 1];

  return instanceType ?? lastInstanceType;
};

export const calculateCredits = (
  fileType: string,
  quantity: number,
  width: number,
  height: number,
  fileMultiplierField: string | undefined = ''
) => {
  const instanceType = getExportInstanceType(width, height);
  const fileTypeData = exportsFileTypes.find(({ id }) => fileType === id);
  if (!fileTypeData) {
    throw Error('File type not specified');
  }
  const fileMultiplier = fileTypeData?.creditMultiplier
    ? fileTypeData?.creditMultiplier?.[fileMultiplierField] ?? 1
    : 1;

  const credits =
    instanceType.creditMultiplier *
    fileTypeData.cpu *
    fileMultiplier *
    quantity;

  return Math.round((credits + Number.EPSILON) * 100) / 100;
};

export const isExportAnimated = (mimeType: string) => {
  return [
    'video/mp4',
    'video/webm',
    'video/quicktime',
    'image/gif',
    'image/apng',
  ].includes(mimeType);
};
