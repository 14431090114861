import { InputMaybe, Scalars, TemplateFormat } from '@typevid/graphql';
import clsx from 'clsx';
import React, { useMemo } from 'react';

const FPI_ANCHOR = 1280;
const getFormatPillIconProps = (
  iconW: number,
  iconH: number,
  width = FPI_ANCHOR,
  height = FPI_ANCHOR,
  strokeWidth = 1
) => {
  let scale = 1;
  let w = iconW;
  let h = iconH;
  if (height > width) {
    w = (width / height) * (iconW - strokeWidth);
    h = iconH - strokeWidth;
    scale = Math.min(Math.max(height / FPI_ANCHOR, 0.5), 1);
  } else {
    w = iconW - strokeWidth;
    h = (height / width) * (iconH - strokeWidth);
    scale = Math.min(Math.max(width / FPI_ANCHOR, 0.5), 1);
  }
  w = w * scale;
  h = h * scale;
  return { w, h, x: (iconW - w) / 2, y: (iconH - h) / 2 };
};

interface FormatPillIconProps {
  formatWidth: number;
  formatHeight: number;
  width?: number;
  height?: number;
  isBase?: InputMaybe<Scalars['Boolean']>;
  className?: string;
}
export const FormatPillIcon: React.FC<FormatPillIconProps> = React.memo(
  ({
    formatWidth,
    formatHeight,
    width = 28,
    height = 28,
    isBase = false,
    className,
  }) => {
    const { w, h, x, y } = useMemo(
      () => getFormatPillIconProps(width, height, formatWidth, formatHeight, 1),
      [formatHeight, formatWidth, height, width]
    );

    return (
      <span className="block relative">
        {isBase && (
          <svg
            className="absolute"
            style={{
              left: x + w - 6,
              top: y - 5.5,
            }}
            width="12"
            height="11"
            viewBox="0 0 15 14"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.21144 0.922194L9.20885 3.91156C9.37795 4.41838 9.85048 4.76169 10.3847 4.7659L13.536 4.79072C14.2565 4.7964 14.5553 5.71587 13.9757 6.14397L11.4409 8.01632C11.0111 8.33376 10.8306 8.88925 10.9917 9.39867L11.9419 12.4034C12.1592 13.0904 11.377 13.6587 10.7908 13.2397L8.22674 11.4075C7.79204 11.0969 7.20796 11.0969 6.77326 11.4075L4.20924 13.2397C3.62299 13.6587 2.84084 13.0904 3.05809 12.4034L4.00828 9.39867C4.16937 8.88925 3.98889 8.33376 3.55913 8.01632L1.02429 6.14397C0.44471 5.71587 0.743465 4.7964 1.46398 4.79072L4.61525 4.7659C5.14952 4.76169 5.62205 4.41838 5.79115 3.91156L6.78855 0.922197C7.01661 0.238694 7.98339 0.238695 8.21144 0.922194Z"
              stroke="white"
              strokeWidth="0.5"
            />
          </svg>
        )}
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          className={className}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x={x}
            y={y}
            width={w}
            height={h}
            rx="1"
            stroke="currentColor"
            strokeWidth="1"
          />
        </svg>
      </span>
    );
  }
);

export interface FormatPillProps extends TemplateFormat {
  onRemove?: (item: TemplateFormat) => void;
  className?: string;
  inverse?: boolean;
}

interface FormatPillBtnProps extends FormatPillProps {
  className?: string;
  tabIndex: number;
  onClick: (e: React.MouseEvent, item: FormatPillProps) => void;
}

export const FormatPillBtn: React.FC<FormatPillBtnProps> = React.memo(
  ({
    id,
    name,
    sizeLabel,
    width,
    height,
    orientation,
    tabIndex,
    className,
    onClick,
  }) => {
    return (
      <button
        type="button"
        tabIndex={tabIndex}
        key={id}
        className="rounded-md text-left border border-gray-200 hover:bg-gray-100"
        onClick={(e) =>
          onClick(e, { id, name, sizeLabel, width, height, orientation })
        }
      >
        <FormatPill
          id={id}
          name={name}
          sizeLabel={sizeLabel}
          width={width}
          height={height}
          orientation={orientation}
          className={className}
        />
      </button>
    );
  }
);

export const FormatPill: React.FC<FormatPillProps> = React.memo(
  ({
    id,
    name,
    sizeLabel,
    width,
    height,
    isBase,
    orientation,
    className = 'text-sm rounded-md',
    inverse = false,
    onRemove,
  }) => {
    return (
      <div
        className={clsx(
          'inline-flex items-center relative w-full text-left overflow-hidden gap-2 py-1 px-2 group select-none',
          className
        )}
      >
        <div
          className={clsx('grid flex-1 overflow-hidden', inverse && 'order-1')}
        >
          <div className="truncate">{name}</div>
          <div className="truncate opacity-70">{sizeLabel}</div>
        </div>
        <FormatPillIcon
          formatWidth={width}
          formatHeight={height}
          isBase={isBase}
        />
        {!!onRemove && (
          <button
            className="hidden group-hover:inline-block absolute right-0 top-0 h-full m-0 p-1 bg-gray-100 hover:bg-gray-200"
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemove({
                id,
                name,
                sizeLabel,
                width,
                height,
                isBase,
                orientation,
              });
            }}
          >
            <span className="sr-only">Remove format</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
            </svg>
          </button>
        )}
      </div>
    );
  }
);
