const convertUnitObj = {
  'px-px': (value: number) =>
    Math.round((value + Number.EPSILON) * 1000) / 1000,
  'px-cm': (value: number) =>
    Math.round((value / 37.79527559055118 + Number.EPSILON) * 1000) / 1000,
  'px-mm': (value: number) =>
    Math.round((value / 3.779527559055118 + Number.EPSILON) * 1000) / 1000,
  'px-in': (value: number) =>
    Math.round((value / 96 + Number.EPSILON) * 1000) / 1000,
  'cm-cm': (value: number) =>
    Math.round((value + Number.EPSILON) * 1000) / 1000,
  'cm-px': (value: number) =>
    Math.round((value * 37.79527559055118 + Number.EPSILON) * 1000) / 1000,
  'cm-mm': (value: number) =>
    Math.round((value * 10 + Number.EPSILON) * 1000) / 1000,
  'cm-in': (value: number) =>
    Math.round((value * 0.3937 + Number.EPSILON) * 1000) / 1000,
  'mm-mm': (value: number) =>
    Math.round((value + Number.EPSILON) * 1000) / 1000,
  'mm-px': (value: number) =>
    Math.round((value * 3.779527559055118 + Number.EPSILON) * 1000) / 1000,
  'mm-cm': (value: number) =>
    Math.round((value / 10 + Number.EPSILON) * 1000) / 1000,
  'mm-in': (value: number) =>
    Math.round((value * 0.03937 + Number.EPSILON) * 1000) / 1000,
  'in-in': (value: number) =>
    Math.round((value + Number.EPSILON) * 1000) / 1000,
  'in-px': (value: number) =>
    Math.round((value * 96 + Number.EPSILON) * 1000) / 1000,
  'in-cm': (value: number) =>
    Math.round((value / 0.3937 + Number.EPSILON) * 1000) / 1000,
  'in-mm': (value: number) =>
    Math.round((value / 0.03937 + Number.EPSILON) * 1000) / 1000,
};
export const convertUnit = (
  value: number | string = 1,
  from: 'px' | 'cm' | 'mm' | 'in',
  to: 'px' | 'cm' | 'mm' | 'in'
): number => {
  if (convertUnitObj[`${from}-${to}`]) {
    return convertUnitObj[`${from}-${to}`](
      typeof value === 'string' ? parseFloat(value) : value
    );
  }
  return typeof value === 'string' ? parseFloat(value) : value;
};
