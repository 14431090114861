import clsx from 'clsx';
import React from 'react';

export interface CoinProps {
  bgColor?: string;
  frontColor?: string;
  label?: string;
  className?: string;
  description?: string;
  height?: number;
  width?: number;
}

const CoinComponent: React.FC<CoinProps> = ({
  className,
  bgColor = '#F8C202',
  frontColor = 'white',
  label,
  description,
  width = 14,
  height = 14,
}) => {
  return (
    <div className={clsx('inline-flex flex-col justify-end', className)}>
      <div className="inline-flex items-center justify-end space-x-0.5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
        >
          <circle cx="12" cy="12" r="10" fill={bgColor} />
          <path
            d="M17 16.5616C16.4549 17.0249 15.8444 17.3805 15.1684 17.6283C14.4925 17.8761 13.792 18 13.067 18C12.511 18 11.9741 17.9273 11.4563 17.7818C10.9439 17.6418 10.4614 17.4424 10.009 17.1838C9.56201 16.9199 9.15318 16.6047 8.7825 16.2384C8.41183 15.8721 8.09294 15.468 7.82584 15.0263C7.56419 14.5791 7.35977 14.1024 7.21259 13.596C7.07086 13.0842 7 12.5535 7 12.004C7 11.4545 7.07086 10.9239 7.21259 10.4121C7.35977 9.90034 7.56419 9.42357 7.82584 8.98182C8.09294 8.53468 8.41183 8.12795 8.7825 7.76162C9.15318 7.39529 9.56201 7.08283 10.009 6.82424C10.4614 6.56027 10.9439 6.35825 11.4563 6.21818C11.9741 6.07273 12.511 6 13.067 6C13.792 6 14.4925 6.12391 15.1684 6.37172C15.8444 6.61414 16.4549 6.9697 17 7.43838L15.7572 9.45859C15.4137 9.09226 15.0076 8.81212 14.5388 8.61818C14.07 8.41886 13.5794 8.31919 13.067 8.31919C12.5492 8.31919 12.0641 8.41616 11.6116 8.6101C11.1592 8.80404 10.764 9.06801 10.426 9.40202C10.088 9.73064 9.82093 10.1212 9.62469 10.5737C9.42845 11.0209 9.33034 11.4976 9.33034 12.004C9.33034 12.5104 9.42845 12.9872 9.62469 13.4343C9.82093 13.8761 10.088 14.264 10.426 14.598C10.764 14.932 11.1592 15.196 11.6116 15.3899C12.0641 15.5838 12.5492 15.6808 13.067 15.6808C13.5794 15.6808 14.07 15.5838 14.5388 15.3899C15.0076 15.1906 15.4137 14.9077 15.7572 14.5414L17 16.5616Z"
            fill={frontColor}
          />
        </svg>
        {label && <span>{label}</span>}
      </div>
      {description && <div className="text-xs opacity-70">{description}</div>}
    </div>
  );
};

export const Coin = React.memo(CoinComponent);
export default CoinComponent;
