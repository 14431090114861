import { MediaExportStatusType, MediaModeType } from '@typevid/graphql';
import clsx from 'clsx';
import React, { useMemo } from 'react';

interface EditorStatusProps {
  status: MediaExportStatusType | MediaModeType;
}
const EditorStatusComponent: React.FC<EditorStatusProps> = ({ status }) => {
  const colorCls = useMemo(() => {
    switch (status) {
      case MediaExportStatusType.Failed:
        return 'bg-red-200 text-red-900';
      case MediaExportStatusType.Exporting:
        return 'bg-yellow-200 text-yellow-800';
      case MediaExportStatusType.Exported:
        return 'bg-green-200 text-green-800';
      case MediaModeType.Edit:
        return 'bg-gray-200 text-gray-600';
      case MediaModeType.Preview:
        return 'text-gray-200 bg-gray-600';
      default:
        return 'bg-gray-200 text-gray-600';
    }
  }, [status]);

  const statusName = useMemo(() => {
    switch (status) {
      case MediaModeType.Edit:
        return 'Open';
      case MediaModeType.Preview:
        return 'Closed';
      default:
        return status?.toLowerCase();
    }
  }, [status]);

  return (
    <span
      className={clsx(
        'inline-flex px-2 py-px text-xs capitalize tracking-wide leading-5 font-medium rounded-full select-none',
        colorCls
      )}
    >
      {statusName}
    </span>
  );
};

export const EditorStatus = React.memo(EditorStatusComponent);
export default EditorStatusComponent;
