import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** File custom scalar type */
  File: any;
  /** JSONObject custom scalar type */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

/** Asset Source */
export enum AssetSource {
  Media = 'MEDIA',
  Template = 'TEMPLATE'
}

/** Asset Type */
export enum AssetType {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['String'];
  /** JWT refresh token */
  refreshToken: Scalars['String'];
  user: User;
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type CreateMediaInput = {
  projectId: Scalars['String'];
  title: Scalars['String'];
};

export type CreateProjectInput = {
  description?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Array<TemplateFormat>>;
  fps?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DuplicateMediaInput = {
  mediaId: Scalars['String'];
};

export type ExportFile = {
  credits: Scalars['Float'];
  fileSettings?: InputMaybe<ExportFileSettingsInput>;
  fileType: Scalars['String'];
  format: Scalars['String'];
  quantity: Scalars['String'];
  scenes?: InputMaybe<Scalars['String']>;
};

export type ExportFileSettingsInput = {
  bleed?: InputMaybe<Scalars['String']>;
  fps?: InputMaybe<Scalars['String']>;
  quality?: InputMaybe<Scalars['String']>;
  transparent?: InputMaybe<Scalars['String']>;
};

export type ExportMediaInput = {
  files: Array<ExportFile>;
  mediaId: Scalars['String'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Media = {
  __typename?: 'Media';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['Date'];
  createdBy: Scalars['String'];
  creator: User;
  exportedAt?: Maybe<Scalars['Date']>;
  exports: Array<MediaExportTiny>;
  id: Scalars['ID'];
  mode: MediaModeType;
  project: Project;
  projectId: Scalars['String'];
  scenes: Scalars['JSONObject'];
  source: MediaSourceType;
  templateId?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['JSONObject']>;
  title: Scalars['String'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['Date'];
};

export type MediaEdge = {
  __typename?: 'MediaEdge';
  cursor: Scalars['String'];
  node: Media;
};

export type MediaExport = {
  __typename?: 'MediaExport';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['Date'];
  createdBy: Scalars['String'];
  creator: User;
  credits?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['JSONObject']>;
  exportedAt?: Maybe<Scalars['Date']>;
  fileSettings: Scalars['JSONObject'];
  fileType?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  jobId?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  media: Media;
  mediaId: Scalars['String'];
  mimetype?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  scenes?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  source: MediaExportSourceType;
  status: MediaExportStatusType;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['Date'];
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type MediaExportConnection = {
  __typename?: 'MediaExportConnection';
  edges?: Maybe<Array<MediaExportEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type MediaExportEdge = {
  __typename?: 'MediaExportEdge';
  cursor: Scalars['String'];
  node: MediaExport;
};

/** Media Export Source Type */
export enum MediaExportSourceType {
  Api = 'API',
  Web = 'WEB'
}

/** Media Export Status Type */
export enum MediaExportStatusType {
  Exported = 'EXPORTED',
  Exporting = 'EXPORTING',
  Failed = 'FAILED',
  Submitted = 'SUBMITTED'
}

export type MediaExportTiny = {
  __typename?: 'MediaExportTiny';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  status: MediaExportStatusType;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['Date'];
};

/** Media Status Mode */
export enum MediaModeType {
  Edit = 'EDIT',
  Preview = 'PREVIEW'
}

/** Media Source Type */
export enum MediaSourceType {
  Api = 'API',
  Web = 'WEB'
}

export type Mutation = {
  __typename?: 'Mutation';
  addProjectAsset: ProjectAsset;
  changePassword: User;
  createMedia: Media;
  createProject: Project;
  deleteMedia: Media;
  deleteProjectAsset: ProjectAsset;
  duplicateMedia: Media;
  exportMedia: Array<MediaExport>;
  login: Auth;
  refreshToken: Token;
  signup: Auth;
  updateFavoriteProject: Project;
  updateMedia: Media;
  updateTemplate: Template;
  updateUser: User;
};


export type MutationAddProjectAssetArgs = {
  externalUrl?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['Upload']>;
  projectId: Scalars['String'];
  source: AssetSource;
  uiId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  data: ChangePasswordInput;
};


export type MutationCreateMediaArgs = {
  data: CreateMediaInput;
};


export type MutationCreateProjectArgs = {
  data: CreateProjectInput;
};


export type MutationDeleteMediaArgs = {
  id: Scalars['String'];
};


export type MutationDeleteProjectAssetArgs = {
  filename: Scalars['String'];
  id: Scalars['String'];
  key: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationDuplicateMediaArgs = {
  data: DuplicateMediaInput;
};


export type MutationExportMediaArgs = {
  data: ExportMediaInput;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String'];
};


export type MutationSignupArgs = {
  data: SignupInput;
};


export type MutationUpdateFavoriteProjectArgs = {
  favorite: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationUpdateMediaArgs = {
  data: UpdateMediaInput;
  id: Scalars['String'];
};


export type MutationUpdateTemplateArgs = {
  data: UpdateTemplateInput;
  id: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};

export type Order = {
  direction?: InputMaybe<OrderDirection>;
  field?: InputMaybe<Scalars['String']>;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PaginationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type Project = {
  __typename?: 'Project';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['Date'];
  createdBy: Scalars['String'];
  creator: User;
  description?: Maybe<Scalars['String']>;
  favorite: Scalars['Boolean'];
  id: Scalars['ID'];
  medias?: Maybe<Array<Media>>;
  name: Scalars['String'];
  template: Template;
  templateId: Scalars['String'];
  totalMedias?: Maybe<Scalars['Int']>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['Date'];
};

export type ProjectAsset = {
  __typename?: 'ProjectAsset';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['Date'];
  createdBy: Scalars['String'];
  /** Client only field. Used for optimistic response */
  file?: Maybe<Scalars['File']>;
  height: Scalars['Float'];
  id: Scalars['ID'];
  key: Scalars['String'];
  mimetype: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  size?: Maybe<Scalars['String']>;
  source: AssetSource;
  type: AssetType;
  /** ID generating from client to keep track of uploading state */
  uiId?: Maybe<Scalars['String']>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['Date'];
  /** Client only field. Used for optimistic response */
  uploading?: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
  width: Scalars['Float'];
};

export type ProjectAssetConnection = {
  __typename?: 'ProjectAssetConnection';
  edges?: Maybe<Array<ProjectAssetEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ProjectAssetEdge = {
  __typename?: 'ProjectAssetEdge';
  cursor: Scalars['String'];
  node: ProjectAsset;
};

export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  edges?: Maybe<Array<ProjectEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  cursor: Scalars['String'];
  node: Project;
};

export type ProjectMediaConnection = {
  __typename?: 'ProjectMediaConnection';
  edges?: Maybe<Array<MediaEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  me: User;
  recentExports: Array<MediaExport>;
  recentMedias: Array<Media>;
  searchStockMedia: StockMediaResults;
  userFavoriteProjects: Array<Project>;
  userMedia: Media;
  userMediaExport: MediaExport;
  userMediaExports: MediaExportConnection;
  userProject: Project;
  userProjectAssets: ProjectAssetConnection;
  userProjectMedias: ProjectMediaConnection;
  userProjects: ProjectConnection;
  userTemplate: Template;
};


export type QueryRecentExportsArgs = {
  projectId?: InputMaybe<Scalars['String']>;
};


export type QueryRecentMediasArgs = {
  projectId?: InputMaybe<Scalars['String']>;
};


export type QuerySearchStockMediaArgs = {
  data: SearchStockMediaInput;
};


export type QueryUserFavoriteProjectsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryUserMediaArgs = {
  id: Scalars['String'];
};


export type QueryUserMediaExportArgs = {
  id: Scalars['String'];
};


export type QueryUserMediaExportsArgs = {
  id: Scalars['String'];
  order: Order;
  page: PaginationArgs;
};


export type QueryUserProjectArgs = {
  id: Scalars['String'];
};


export type QueryUserProjectAssetsArgs = {
  id: Scalars['String'];
  page: PaginationArgs;
  query?: InputMaybe<Scalars['String']>;
  source: AssetSource;
};


export type QueryUserProjectMediasArgs = {
  id: Scalars['String'];
  order: Order;
  page: PaginationArgs;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryUserProjectsArgs = {
  order: Order;
  page: PaginationArgs;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryUserTemplateArgs = {
  id: Scalars['String'];
};

/** User role */
export enum Role {
  Admin = 'ADMIN',
  User = 'USER'
}

export type SearchStockMediaInput = {
  /** Source should be one of these values: (UNSPLASH, PIXABAY, PEXELS) */
  orientation?: InputMaybe<StockMediaOrientation>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
  /** Source should be one of these values: (UNSPLASH, PIXABAY, PEXELS) */
  source?: InputMaybe<StockMediaSource>;
};

export type SignupInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type StockMedia = {
  __typename?: 'StockMedia';
  altDescription?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pageURL: Scalars['String'];
  previewURL: Scalars['String'];
  sourceName: Scalars['String'];
  sourceURL: Scalars['String'];
  src: StockMediaSrc;
  userName: Scalars['String'];
  userURL: Scalars['String'];
};

/** Search Stock Orientation */
export enum StockMediaOrientation {
  All = 'ALL',
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
  Square = 'SQUARE'
}

export type StockMediaResults = {
  __typename?: 'StockMediaResults';
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  rateLimit: Scalars['String'];
  rateLimitRemaining: Scalars['String'];
  results: Array<StockMedia>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Search Stock Source */
export enum StockMediaSource {
  Pexels = 'PEXELS',
  Pixabay = 'PIXABAY',
  Unsplash = 'UNSPLASH'
}

export type StockMediaSrc = {
  __typename?: 'StockMediaSrc';
  large: StockMediaSrcItem;
  medium: StockMediaSrcItem;
  original: StockMediaSrcItem;
  small: StockMediaSrcItem;
};

export type StockMediaSrcItem = {
  __typename?: 'StockMediaSrcItem';
  height: Scalars['Int'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type Template = {
  __typename?: 'Template';
  animations: Scalars['JSONObject'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['Date'];
  createdBy: Scalars['String'];
  creator: User;
  id: Scalars['ID'];
  motions: Scalars['JSONObject'];
  name?: Maybe<Scalars['String']>;
  project: Project;
  scenes: Scalars['JSONObject'];
  settings: Scalars['JSONObject'];
  styles: Scalars['JSONObject'];
  themes: Scalars['JSONObject'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['Date'];
};

export type TemplateFormat = {
  height: Scalars['Float'];
  id: Scalars['String'];
  isBase?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  orientation: Scalars['Int'];
  sizeLabel: Scalars['String'];
  width: Scalars['Float'];
};

export type Token = {
  __typename?: 'Token';
  /** JWT access token */
  accessToken: Scalars['String'];
  /** JWT refresh token */
  refreshToken: Scalars['String'];
};

export type UpdateMediaInput = {
  scenes?: InputMaybe<Scalars['JSONObject']>;
  theme?: InputMaybe<Scalars['JSONObject']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateTemplateInput = {
  animations?: InputMaybe<Scalars['JSONObject']>;
  motions?: InputMaybe<Scalars['JSONObject']>;
  scenes?: InputMaybe<Scalars['JSONObject']>;
  settings?: InputMaybe<Scalars['JSONObject']>;
  styles?: InputMaybe<Scalars['JSONObject']>;
  themes?: InputMaybe<Scalars['JSONObject']>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  projects: Array<Project>;
  role: Role;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['Date'];
};
