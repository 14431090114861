import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { ApolloClient, ApolloError } from '@apollo/client';
import {
  MeQuery,
  LoginMutation,
  SignupMutation,
  User,
  Mutation,
  Query,
  LoginInput,
  SignupInput,
} from '@typevid/graphql';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { LoadingScreen } from '../loading-screen/loading-screen';
import { routePath } from '../_data';

interface AuthActionDataInterface {
  login: { loading: boolean; error?: ApolloError | null };
  signup: { loading: boolean; error?: ApolloError | null };
}

interface AuthContextProps {
  isAuthenticated: boolean;
  user: User | null;
  scope: string | null;
  isLoading: boolean;
  authActionData: AuthActionDataInterface;
  login?: (email: string, password: string) => void;
  signup?: (
    email: string,
    password: string,
    firstName?: string,
    lastName?: string
  ) => void;
  logout?: () => void;
}

const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  user: null,
  scope: null,
  isLoading: false,
  authActionData: {
    login: { loading: false },
    signup: { loading: false },
  },
});

interface AuthProviderProps {
  children: React.ReactNode;
  apolloClient: ApolloClient<unknown>;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  apolloClient,
}) => {
  //const router = useRouter();

  const [{ user, scope, loading, authActionData }, setState] = useState<{
    user: User | null;
    scope: string | null;
    loading: boolean;
    authActionData: AuthActionDataInterface;
  }>({
    user: null,
    scope: null,
    loading: true,
    authActionData: {
      login: { loading: false },
      signup: { loading: false },
    },
  });

  useEffect(() => {
    async function loadUserFromStorage() {
      const token = localStorage.getItem('token');
      let user: User | null = null;
      if (token) {
        //Got a token in the cookies, let's see if it is valid
        try {
          const { data } = await apolloClient.query<{ me: Query['me'] }>({
            query: MeQuery,
          });
          if (data?.me) {
            user = data.me;
          }
        } catch (error) {
          console.log('auth error', error);
        }
      }
      setState((s) => ({
        ...s,
        user,
        scope: user?.id ?? null,
        loading: false,
      }));
    }
    loadUserFromStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      Sentry.setUser({ email: user.email, id: user.id });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  const login = async (email: string, password: string) => {
    setState((s) => ({
      ...s,
      authActionData: {
        ...s.authActionData,
        login: {
          error: null,
          loading: true,
        },
      },
    }));
    try {
      await apolloClient.resetStore();
      const { data } = await apolloClient.mutate<
        { login: Mutation['login'] },
        LoginInput
      >({
        mutation: LoginMutation,
        variables: { email, password },
      });

      localStorage.setItem('token', data?.login?.refreshToken ?? '');
      setState((s) => ({
        ...s,
        user: data?.login?.user ?? null,
        scope: data?.login?.user?.id ?? null,
        authActionData: {
          ...s.authActionData,
          login: {
            error: null,
            loading: false,
          },
        },
      }));
      //router.push('/dashboard');
    } catch (error) {
      setState((s) => ({
        ...s,
        authActionData: {
          ...s.authActionData,
          login: {
            error: error as ApolloError,
            loading: false,
          },
        },
      }));
    }
  };

  const signup = async (
    email: string,
    password: string,
    firstName?: string,
    lastName?: string
  ) => {
    setState((s) => ({
      ...s,
      authActionData: {
        ...s.authActionData,
        signup: {
          error: null,
          loading: true,
        },
      },
    }));

    try {
      await apolloClient.resetStore();
      const { data } = await apolloClient.mutate<
        { signup: Mutation['signup'] },
        SignupInput
      >({
        mutation: SignupMutation,
        variables: { email, password, firstName, lastName },
      });
      localStorage.setItem('token', data?.signup?.refreshToken ?? '');
      setState((s) => ({
        ...s,
        user: data?.signup?.user ?? null,
        scope: data?.signup?.user?.id ?? null,
        authActionData: {
          ...s.authActionData,
          signup: {
            error: null,
            loading: false,
          },
        },
      }));
      //router.push(routePath.dashboard);
    } catch (error) {
      setState((s) => ({
        ...s,
        authActionData: {
          ...s.authActionData,
          signup: {
            error: error as ApolloError,
            loading: false,
          },
        },
      }));
    }
  };

  const logout = async () => {
    localStorage.removeItem('token');
    setState((s) => ({
      ...s,
      user: null,
      scope: null,
    }));
    await apolloClient.resetStore();
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        isLoading: loading,
        user,
        scope,
        authActionData,
        login,
        signup,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

// eslint-disable-next-line @typescript-eslint/ban-types
type ExtraInfoType = {};
export function withProtectRoute<P extends JSX.IntrinsicAttributes>(
  WrappedComponent: React.ComponentType<P & ExtraInfoType>
) {
  const ComponentWithProtectRoute = (props: P) => {
    const router = useRouter();
    const { isAuthenticated, isLoading } = useAuth();
    const rina = useMemo(
      () => !(isLoading || isAuthenticated),
      [isAuthenticated, isLoading]
    ); // Redirect if not authenticated

    useEffect(() => {
      if (rina && router.isReady) {
        router.push(
          router?.asPath.startsWith(routePath.logout)
            ? routePath.login
            : `${routePath.login}?redirect=${
                router?.asPath ?? routePath.dashboard
              }`
        );
      }
    }, [rina, router]);

    if (isAuthenticated) {
      return <WrappedComponent {...props} />;
    }
    return <LoadingScreen />;
  };
  return ComponentWithProtectRoute;
}

export function withRedirectRoute<P extends JSX.IntrinsicAttributes>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithProtectRoute = (props: P) => {
    const router = useRouter();
    const { isAuthenticated, isLoading, user } = useAuth();
    const ria = useMemo(
      () =>
        !isLoading &&
        isAuthenticated &&
        [routePath.login, routePath.signup, '/'].includes(router.pathname),
      [isAuthenticated, isLoading, router.pathname]
    ); // Redirect if authenticated)

    useEffect(() => {
      if (ria) {
        router.push(
          (router.query?.redirect as string) ?? user?.id ?? routePath.dashboard
        );
      }
    }, [ria, router, user?.id]);

    if (!ria) {
      return <WrappedComponent {...props} />;
    }
    return <LoadingScreen />;
  };
  return ComponentWithProtectRoute;
}
