interface BaseObject {
  id: string;
  name: string;
}

interface SiteMenu extends BaseObject {
  id: string;
  name: string;
  link: string;
  protected: boolean;
}

export const routePath = {
  dashboard: '/dashboard',
  account: '/account',
  projects: '/projects',
  project: 'project',
  designer: '/designer',
  editor: '/editor',
  login: '/login',
  logout: '/logout',
  signup: '/signup',
  templates: '/templates',
  product: '/product',
  pricing: '/pricing',
};

export const menuMain: SiteMenu[] = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    link: routePath.dashboard,
    protected: true,
  },
  {
    id: 'templates',
    name: 'Templates',
    link: routePath.templates,
    protected: true,
  },
  {
    id: 'product',
    name: 'Product',
    link: routePath.product,
    protected: false,
  },
  {
    id: 'templates',
    name: 'Templates',
    link: routePath.templates,
    protected: false,
  },
  {
    id: 'pricing',
    name: 'Pricing',
    link: routePath.pricing,
    protected: false,
  },
];

export const menuRight: SiteMenu[] = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    link: routePath.dashboard,
    protected: true,
  },
  {
    id: 'settings',
    name: 'Settings',
    link: `${routePath.dashboard}/settings`,
    protected: true,
  },
  {
    id: 'login',
    name: 'Login',
    link: routePath.login,
    protected: false,
  },
  {
    id: 'signup',
    name: 'Join Beta',
    link: routePath.signup,
    protected: false,
  },
];
