import { useMemo } from 'react';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import merge from 'deepmerge';
import { AppProps } from 'next/app';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<unknown>;

// const httpLink = createHttpLink({
//   uri: `${process.env.API_PATH}/graphql`, // Server URL (must be absolute)
//   credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
// });

const uploadLink = createUploadLink({
  uri: `${process.env.API_PATH}/graphql`, // Server URL (must be absolute)
  credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
  return null;
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([
      authLink,
      errorLink,
      uploadLink as unknown as ApolloLink,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            // userProjectAssets: relayStylePagination(),
          },
        },
        Mutation: {
          fields: {
            // addTemplateAsset: {
            //   merge(_, incoming, { cache }) {
            //     cache.modify({
            //       fields: {
            //         userTemplateAssets(existing = []) {
            //           return [incoming, ...existing];
            //         },
            //       },
            //     });
            //     return incoming;
            //   },
            // },
            // deleteTemplateAsset: {
            //   merge(_, incoming, { cache }) {
            //     cache.modify({
            //       fields: {
            //         userTemplateAssets(existing = []) {
            //           return existing.filter(
            //             ({ __ref }) => __ref !== incoming.__ref
            //           );
            //         },
            //       },
            //     });
            //     return incoming;
            //   },
            // },
            // addMediaAsset: {
            //   merge(_, incoming, { cache }) {
            //     cache.modify({
            //       fields: {
            //         userMedia(existing = []) {
            //           try {
            //             let media: EditorMediaData = cache.readFragment({
            //               id: existing.__ref,
            //               fragment: MediasFragments.MediaFull,
            //             });
            //             const asset: MediaAssets = cache.readFragment({
            //               id: incoming.__ref,
            //               fragment: MediasFragments.MediaAsset,
            //             });
            //             media = {
            //               ...media,
            //               assets: [asset, ...media.assets],
            //             };
            //             return media;
            //           } catch (error) {
            //             console.error(error);
            //           }
            //         },
            //       },
            //     });
            //     return incoming;
            //   },
            // },
            // deleteMediaAsset: {
            //   merge(_, incoming, { cache }) {
            //     cache.modify({
            //       fields: {
            //         userMedia(existing = []) {
            //           try {
            //             let media: EditorMediaData = cache.readFragment({
            //               id: existing.__ref,
            //               fragment: MediasFragments.MediaFull,
            //             });
            //             const asset: MediaAssets = cache.readFragment({
            //               id: incoming.__ref,
            //               fragment: MediasFragments.MediaAsset,
            //             });
            //             media = {
            //               ...media,
            //               assets: media.assets.filter(
            //                 ({ id }) => id !== asset.id
            //               ),
            //             };
            //             return media;
            //           } catch (error) {
            //             console.error(error);
            //           }
            //         },
            //       },
            //     });
            //     return incoming;
            //   },
            // },
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<unknown>,
  pageProps: { props: AppProps }
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export interface CustomPageProps {
  title: string;
}
export function useApollo(pageProps: CustomPageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
